* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.items {
  padding:0.2rem 0 
}

.container {
  display: flex;
  justify-content: center;
}

.clients-list-wrapper {
  max-width: 1000px; /* Adjust as needed */
  width: 100%;
}

.clients-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.client {
  flex: 1 0 calc(25% - 20px); /* Show 4 images per row on large screens */
  margin: 10px; /* Adjust spacing */
  box-sizing: border-box;
}

/* Media Queries */
@media (max-width: 1023px) {
  .client {
    flex-basis: calc(50% - 20px); /* Show 2 images per row on medium screens */
  }
}

@media (max-width: 768px) {
  .client {
    flex-basis: calc(100% - 20px); /* Show 1 image per row on small screens */
  }
}

