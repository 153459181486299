@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  padding-top: 20;
}
:root {
  --white: #ffffff;
  --black: #52525b;

  --font-size-heading: 48px;
  --font-size-subheading: 32px;

  --font-size-nav-heading: 22px;
  --font-size-card-heading: 24px;
  --font-size-heading-label: 18px;
  --font-size-paragraph: 16px;
  --font-size-nav-link: 16px;
  --font-size-nav-paragraph: 14px;
  --font-size-large-para: 20px;
  --font-size-heading-label: 12px;

  --line-height-heading: 54px;
  --line-height-heading-label: 24px;

  --line-height-subheading: 36px;
  --line-height-nav-heading: 28px;
  --line-height-card-heading: 26px;

  --line-height-paragraph: 24px;
  --line-height-nav-paragraph: 22px;
  --line-height-large-para: 34px;
  --line-height-nav-link: 22px;

  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;

  --secondary-color: #643db4;
}
.secondary-color {
  background-color: var(--secondary-color);
}
.meta-heading {
  font-size: var(--font-size-heading);
  line-height: var(--line-height-heading);
  color: var(--white);
  font-weight: var(--weight-semibold);
}
.meta-heading-label {
  font-size: var(--font-size-heading-label);
  line-height: var(--line-height-heading-label);
  color: var(--black);
  font-weight: var(--weight-medium);
}
.meta-large-para {
  font-size: var(--font-size-large-para);
  line-height: var(--line-height-large-para);
  color: var(--black);
  font-weight: var(--weight-normal);
}
.meta-subheading {
  font-size: var(--font-size-subheading);
  line-height: var(--line-height-subheading);
  font-weight: var(--weight-semibold);
  color: black;
}
.meta-paragraph {
  font-size: var(--font-size-paragraph);
  line-height: var(--line-height-paragraph);
  font-weight: var(--weight-normal);
}
.meta-nav-paragraph {
  font-size: var(--font-size-nav-paragraph);
  line-height: var(--line-height-nav-paragraph);
  color: var(--heading);
  font-weight: var(--weight-normal);
}
.meta-card-heading {
  font-size: var(--font-size-card-heading);
  line-height: var(--line-height-card-heading);
  color: black;
  font-weight: var(--weight-bold);
}
.meta-nav-heading {
  font-size: var(--font-size-nav-heading);
  line-height: var(--line-height-nav-heading);
  font-weight: var(--weight-semibold);
  text-transform: uppercase;
}
.meta-nav-link {
  font-size: var(--font-size-nav-link);
  line-height: var(--line-height-nav-link);
  font-weight: var(--weight-semibold);
  text-transform: uppercase;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s; 
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@media only screen and (max-width: 1200px) {
  .uniq-lg-ul{
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  :root {
    --font-size-subheading: 28px;
  }
}
.white-image {
  filter: brightness(0) invert(1);
}

.image-containers {
  width: 100%;
  /* display: flex; */
}

.image-containers .box1 {
  width: 450px;
  height: 550px;
  background: var(--secondary-color);
  background-position: top;
  transition: ease-in-out 4s;
}

.image-containers .box2 {
  width: 450px;
  height: 550px;
  background: var(--secondary-color);
  /* background-position: top; */
  /* transition: ease-in-out 4s; */
}

@media (max-width: 1496px) {
  .image-containers .box {
    width: 380px;
  }
}

@media (max-width: 1278px) {
  .image-containers .box {
    width: 290px;
  }
}

@media (max-width: 1024px) {
  .image-containers .box {
    width: 100%;
  }
}

.image-containers .box.box1 {
  /* background-size: fill; */
  max-height: 100%;
  max-width: 100%;
}

.image-containers .box:hover {
  background-position: bottom;
}

/* Assuming you have a .logo-container class for styling */
.logo-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Ensure the content doesn't overflow outside the card */
}

.logo-container img {
  width: 100%;
}

.logo-container .content-container {
  position: absolute;
  inset: 0;
  padding: 0 6px;
  background-color: var(--secondary-color);
  color: white;
  overflow: hidden;
  transform: translateY(100%);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logo-container:hover .content-container::before {
  content: "";
  position: absolute;
  inset: 0;
  /* background-color: var(--secondary-color); */
  transform: translateY(0);
  /* Adjust the percentage based on your needs */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Adjust the duration and easing function as needed */
}

.logo-container:hover .content-container {
  transform: translateY(78%);
}

.blue-image-container {
  position: relative;
}

.blue-image {
  width: 100%;
  height: auto;
  display: block;
}

.blue-image::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, var(--secondary-color));
}

@media (max-width: 767px) {
  /* Small screens */
  /* .small-screen-image {
    display: block;
  }

  .large-screen-image {
    display: none;
  } */
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");

@media (min-width: 768px) {
  /* Large screens */
  /* .small-screen-image {
    display: none;
  }

  .large-screen-image {
    display: block;
  } */
}

@media (max-width: 1000px) {
  /* Small screens */
  /* .small-screen-img {
    display: block;
  }

  .large-screen-img {
    display: none;
  } */
}

.image-carousel-container {
  width: 83%;
  overflow: hidden;
}

.image-carousel-wrapper {
  display: flex;
  transition: transform 1s ease-in-out;
}

input[type="text"]:focus ~ .input-text {
  @apply text-blue-500 bg-white transform -translate-y-6 -translate-x-4 scale-75;
}

input[type="text"]:valid ~ .input-text {
  @apply text-blue-500 bg-white transform -translate-y-6 -translate-x-4 scale-75;
}

textarea[id="message"]:focus ~ .input-text {
  @apply text-blue-500 bg-white transform -translate-y-6 -translate-x-4 scale-75;
}

textarea[id="message"]:valid ~ .input-text {
  @apply text-blue-500 bg-white transform -translate-y-6 -translate-x-4 scale-75;
}

.carousel-image {
  width: calc(100% / 4);
  /* Adjust the width based on the number of imagesPerSlide */
  height: auto;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;500;700&display=swap");

.p0 {
  padding: 0 !important;
}

a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
span {
  font-family: Poppins, sans-serif;
  margin: 0;
}

.logo-side-img img,
.what-tab-st li img,
.logo-inner-u-txt ul li img {
  transition: all 0.8s ease-in-out;
}

.logo-side-img img:hover {
  transform: scale(1.3);
  transition: all 0.8s ease-in-out;
}

.what-tab-st li:hover img,
.logo-inner-u-txt ul li:hover img {
  transform: scale(1.2);
  transition: all 0.8s ease-in-out;
}

body {
  overflow-x: hidden;
}

.form-btn-st:focus,
a:focus,
a:hover,
button:focus,
button:hover {
  text-decoration: none !important;
  outline: 0;
}

section {
  float: left;
  width: 100%;
}

.tp-hd-call ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

.tp-hd-call ul li a {
  color: #231f20;
  font-size: 15px;
  padding-left: 25px;
}

.tp-hd-mrq {
  max-width: 650px;
}

.tp-hd-mrq p span {
  font-weight: 500;
  color: #d967fd;
}

.tp-hd-mrq p {
  font-size: 13px;
  color: #f02082;
}

.top-header {
  padding: 10px 0;
}

.top-header .row {
  display: flex;
  align-items: center;
}

.header-top {
  display: block;
  background: #00273f;
  text-align: right;
}

.hd-top-main-nv ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
}

.hd-top-main-nv ul li {
  margin: 0 20px;
}

.hd-top-main-nv ul li.active a {
  color: #f0b555;
}

.hd-top-main-nv ul li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.head_top.dekstop-nav {
  background: #f1f2f2;
  padding: 12px 0;
}

.head_top.dekstop-nav .col-sm-12 {
  display: flex;
  align-items: center;
}

.hd-top-main-nv {
  padding: 12px 0;
  background: var(--secondary-color);
}

.hd-list-nav li .hd-gt-strt {
  margin-right: 0;
  padding: 10px 25px;
  background: var(--secondary-color);
  border-radius: 21px 30px 4px 21px;
  font-size: 13px;
  font-weight: 700;
  margin-right: 20px;
  text-transform: capitalize;
  color: #fff;
}

.hd-list-nav li a:hover {
  color: #f02082;
}

.hd-top-main-nv ul li a:hover {
  color: #ecaa55;
}

.header-top ul {
  padding: 0;
  margin: 0;
}

.header-top ul li {
  display: inline-block;
  padding: 10px 15px;
}

.header-top ul li a {
  font-size: 15px;
  color: #fff;
}

.navbar-right a.yellow_btn_m {
  margin: 0 15px;
  position: relative;
  padding: 12px 19px 12px 45px !important;
}

.navbar-right a.yellow_btn_m:before {
  content: "";
  background-image: url(../src/assets/images/1.jpg);
  position: absolute;
  height: 32px;
  width: 28px;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.mobile-top {
  display: none;
}

.hd-list-nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.hd-list-nav ul {
  width: 50%;
}

.hd-list-nav li a {
  margin: 0 8px;
  color: #231f20;
  font-weight: 500;
  font-size: 15px;
}

.header-top ul li a i {
  margin: 0 3px 0 0;
  font-size: 18px;
}

.head_top {
  padding: 15px 0;
  z-index: 9;
  position: relative;
  left: 0;
  right: 0;
  padding: 0;
}

.head_top .navbar .navbar-header a.navbar-brand {
  font-weight: 700;
  color: #00273f;
  font-size: 26px;
}

.head_top .navbar-right {
  padding-top: 25px;
}

.head_top .navbar.navbar-defult {
  margin-bottom: 0;
}

.head_top .navbar.navbar-defult ul li a:focus,
.head_top .navbar.navbar-defult ul li a:hover,
.head_top .navbar.navbar-defult ul li.active a {
  background: 0 0;
  color: #fa690c;
}

.head_top .navbar.navbar-defult ul li a {
  color: #000;
  opacity: 1;
  font-size: 13px;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  padding: 10px 12px;
  font-weight: 700;
}

.head_top .navbar.navbar-defult ul li a.btn-org {
  border-radius: 50px;
  padding: 12px 27px;
  color: #fff;
  background: linear-gradient(to right, #3ab0ce, #1bc9bb);
  font-size: 13px;
  font-weight: 700;
}

/* .drop-down-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 20px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
} */

.drop-down-heading h3 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  text-transform: uppercase;
  transition: all 0.3s ease-out;
}

.drop-down-heading:hover h3 {
  color: var(--secondary-color);
}

.drop-down-heading p {
  font-size: 14px;
  margin-bottom: 10px;
}

.drop-down-ul ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.drop-down-ul ul li {
  border-bottom: 1px solid #d7d7d7;
}

.drop-down-ul ul li:last-child {
  border-bottom: 0px;
}

.fa-angle-right:before {
  content: "\f105";
}

.drop-view-link,
.drop-view-link:hover,
.drop-view-link:focus {
  color: var(--secondary-color);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: none;
}

.drop-view-link i {
  margin-right: 0;
  transition: all 0.3s;
}

.drop-hover:hover .drop-view-link i {
  margin-right: 5px;
}

.drop-down-ul ul li a {
  padding: 14px 0px 14px 0px;
  display: inherit;
  transition: all 0.1s linear;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #4b4b4b;
  text-decoration: none;
  position: relative;
  width: 100%;
}

.drop-down-ul ul li a:before {
  /* content: '\f105'; */
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  left: 3px;
  font-weight: 900;
  transform: translateY(-50%);
}

.drop-down-ul ul li a:hover {
  background: #f8f8f8;
  color: var(--secondary-color);
  position: relative;
  z-index: 2;
  padding-left: 4px;
}

/* ... (other styles for MegaMenu content) */

.menu > ul > li.drop-down > .drop-down-menu {
  position: absolute;
  padding: 30px 0;
  top: 61px;
  left: 0;
  width: 100%;
  background: #fff;
  list-style: none;
  border-top: 3px solid var(--secondary-color);
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.menu > ul > li.drop-down:hover > .drop-down-menu {
  visibility: visible;
  opacity: 1;
}

/*----------- For Ipad --------------------*/
@media only screen and (max-width: 768px) {
  .banner-bottom-carousel img {
    max-width: 230px;
  }

  .banner-bottom-carousel {
    padding: 0px 105px;
  }

  .banner-heading h1 {
    font-size: 39px;
  }

  .twoinipadscreen div.col-lg-4 {
    width: 50%;
  }

  .displaynoneonipad {
    display: none;
  }

  .ipadwidth50 {
    width: 50%;
  }

  .contentrightonipad {
    justify-content: flex-end;
  }

  .single-pricing {
    margin-bottom: 20px;
  }

  .ipadwidth100 {
    width: 100%;
  }

  .portfolio-search-submit {
    padding: 17px 2px;
  }

  .ipadmargin30 {
    margin-top: 30px;
  }

  .service-tabs .nav-tabs .nav-link {
    padding: 15px 15px 15px 15px;
  }

  .inner-service-tabs .nav-tabs .nav-link {
    padding: 10px 5px;
  }

  .ipadpaddingleft0 {
    padding-left: 0px !important;
  }
}

/*------------------ For Ipad Pro --------------------------*/
@media only screen and (max-width: 1024px) {
  .banner-bottom-carousel {
    padding: 0px 105px;
  }

  .twoinipadscreen div.col-lg-4 {
    width: 50%;
  }
}

.head_top .dropdown-menu > li > a {
  position: relative;
  margin: 0 0 10px;
  font-weight: 500;
  border-left: 4px solid #00273f;
}

.head_top ul.dropdown-menu {
  padding: 20px 0 15px;
  border: none;
  left: 0;
  right: 0;
  width: 250px;
}

.drop-img {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}

.drop-img-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background: rgba(95, 19, 245, 0.7);
  line-height: 100%;
  opacity: 0;
  transition: all 0.3s;
}

span.menu-hover-effect {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color) 50%,
    #fff 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.6s ease;
}

.scrolled .menu > ul > li > a > span.menu-hover-effect,
.white-header .menu > ul > li > a > span.menu-hover-effect {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color) 50%,
    #000 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
}

.menu > ul > li:hover > a > span.menu-hover-effect,
span.menu-hover-effect:hover {
  background-position: 0 100%;
}

.drop-hover p.drop-simple-p {
  font-size: 13px;
  margin-bottom: 5px;
}

.drop-hover:hover h3 {
  color: var(--secondary-color);
}

.drop-hover:hover .drop-img .drop-img-hover {
  opacity: 1;
}

.drop-img-hover p {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.drop-img img {
  width: 100%;
  transition: all 0.3s;
}

.drop-hover:hover .drop-img img {
  transform: scale(1.15);
}

.logo {
  width: 100%;
  margin: 0;
}

.head_top .dropdown-menu > .active > a,
.head_top .dropdown-menu > .active > a:focus,
.head_top .dropdown-menu > .active > a:hover {
  text-decoration: none;
  background-color: transparent;
  outline: 0;
  color: #fc5216 !important;
  border-left: 4px solid #fc5216;
}

.head_top .dropdown-menu > li > a:hover:before {
  color: #fc5216;
}

.head_top .open > a,
.head_top .open > a:focus,
.head_top .open > a:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fc5216 !important;
}

.hd-list-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

section.main-banner {
  background-color: var(--secondary-color);
  margin-top: 65px;
}

.main-banner .live-cht {
  color: #fff;
  font-size: 15px;
}

.main-banner .live-cht:hover {
  color: #e5e5e5;
}

.main-banner .get-strtd:hover {
  background: 0 0;
  border: 1px solid #ddd;
  border-radius: 21px 30px 4px 21px;
  color: #ddd;
  cursor: pointer;
}

/* textarea:focus~label,
textarea:valid~label{
  top: 35px;

} */

.main-banner .row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.bnr-text h5 {
  color: #fff;
  font-size: 14px;
}

.bnr-text p {
  color: #fff;
}

.bnr-text h1 span {
  color: #fff;
}

.bnr-text h1 .bnr-spn-clr {
  color: #fff;
}

.bnr-text h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;
  padding: 10px 0 28px;
  color: #fff;
}

.bnr-text h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 25px;
}

.bnr-text ul {
  padding: 0;
  list-style: none;
}

.bnr-text ul i {
  color: #fff;
}

.bnr-text ul li {
  font-size: 15px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
}

.get-strtd {
  border-radius: 21px 30px 4px 21px;
  background: var(--secondary-color);
  padding: 8px 15px;
  font-size: 15px;
  color: #fff;
  margin-right: 15px;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.live-cht {
  color: var(--secondary-color);
  font-size: 15px;
}

.bnr-btn {
  float: left;
  clear: both;
  width: 100%;
  margin: 20px 0 30px;
}

.bnr-text h6 {
  clear: both;
  color: #fff;
  font-size: 13px;
  border-top: 1px solid #f1f1f1;
  padding-top: 20px;
}

.bnr-img-st {
  background: #101e39;
  text-align: center;
  margin: 0 auto;
  border-radius: 0 0 46px 102px;
  overflow: hidden;
}

.bnr-img-st img {
  width: auto;
  position: relative;
  top: 0;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
}

.bnr-cl-logo {
  position: absolute;
  cursor: pointer;
}

.bnr-cl-logo-1 {
  top: 30px;
  left: 100px;
}

.bnr-cl-logo-2 {
  top: 165px;
  left: -70px;
  transition: all 0.8s ease-in-out;
  width: 100px;
}

.bnr-cl-logo-3 {
  bottom: 140px;
  left: -50px;
  transition: all 0.8s ease-in-out;
  display: none;
}

.bnr-cl-logo-4 {
  top: 200px;
  right: -40px;
  transition: all 0.8s ease-in-out;
}

.bnr-cl-logo-5 {
  bottom: 35px;
  right: 45px;
  transition: all 0.8s ease-in-out;
}

.bnr-cl-logo-7 {
  top: 200px;
  bottom: 0;
  margin: auto;
  left: -105px;
}

.bnr-cl-logo-6 {
  top: 30px;
  right: 100px;
}

.bnr-cl-logo-2:hover,
.bnr-cl-logo-3:hover,
.bnr-cl-logo-4:hover,
.bnr-cl-logo-5:hover {
  transform: scale(1.3);
  transition: all 0.8s ease-in-out;
}

.bnr-cl-logo-4 {
  width: 18%;
  right: -40px;
  display: none;
}

.logo-side-txt h2 {
  font-size: 35px;
  font-weight: 700;
}

.logo-side-txt span {
  font-size: 20px;
  display: block;
  font-weight: 500;
}

.banner-logo .row {
  display: flex;
  align-items: center;
}

.banner-logo {
  padding: 45px 0;
}

.logo-side-img img {
  width: 98%;
  margin: 0 auto;
  display: table;
}

.logo-side-img ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-side-txt {
  position: relative;
}

.logo-side-txt:before {
  content: "";
  background: #ece6eb;
  position: absolute;
  right: -15px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 100px;
}

.what-tab-sec {
  padding: 50px 0;
  position: relative;
  background-color: #cccccc5e;
}

.what-tab-sec .hd-txt {
  text-align: center;
}

.what-icon-1 {
  position: absolute;
  top: 160px;
  left: 210px;
  z-index: -1;
}

.what-icon-2 {
  position: absolute;
  left: 430px;
  bottom: 80px;
  z-index: -1;
}

.what-icon-3 {
  position: absolute;
  top: 220px;
  right: 500px;
  z-index: -1;
}

.what-icon-4 {
  position: absolute;
  right: 70px;
  bottom: 50px;
  z-index: -1;
}

.what-tab-st {
  padding-top: 40px;
  padding-bottom: 35px;
}

.what-tab-st li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.what-tab-st li {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 18%;
  margin: 0 10px;
  padding: 10px 0;
  background: #fff;
}

.what-tab-st li a {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  text-align: center;
  background: 0 0 !important;
}

.what-tab-st li.active a {
  border: 0;
}

.what-tab-st li a,
.what-tab-st li a:hover {
  border: 0 !important;
}

.what-tab-st li:hover a {
  background: 0 0;
}

.what-tab-st ul {
  border: 0;
}

.what-tab-st li.active,
.what-tab-st li:hover {
  background: #d967fd;
}

.what-tab-st li.active a,
.what-tab-st li.active a:focus,
.what-tab-st li.active a:hover,
.what-tab-st li:hover a {
  color: #fff;
}

.what-tab-st li.active p {
  font-size: 20px;
  font-weight: 500;
}

.what-tab-inr-img img {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.what-tab-txt h2 {
  color: #f02082;
  font-weight: 700;
}

.what-tab-txt h4 {
  font-size: 18px;
  font-weight: 700;
  padding: 20px 0;
}

.what-tab-txt p {
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
}

.what-tab-txt .bnr-btn {
  margin: 35px 0 0;
}

.what-tab-main-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.what-tab-inr-img {
  position: relative;
}

.what-tab-inr-img:before {
  content: "";
  background: url(../src/assets/images/2.png);
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  width: 312px;
  height: 411px;
  position: absolute;
  bottom: 5px;
  right: -120px;
  z-index: -1;
}

.what-img-st1 {
  position: absolute;
  left: 0;
}

.what-tab-inr-img:hover .hvr-img-st-wht {
  transform: scale(1.1);
}

.logo-inner-u-txt ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.logo-inner-u-txt {
  padding-left: 75px;
  position: relative;
  width: 800px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.logo-inner-u-txt h3 {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  padding-bottom: 10px;
}

.logo-new-inner-div {
  position: relative;
}

p.cncl-btn {
  position: absolute;
  right: 10px;
  top: -33px;
  color: #28befe;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
}

p.cncl-btn:before {
  top: 0;
  left: 0;
  background: #000;
  width: 100%;
  height: 2px;
}

.logo-inner-u-txt:before {
  content: "";
  position: absolute;
  left: -30px;
  height: 2px;
  width: 100%;
  background: #28befe;
  top: -19px;
  z-index: 0;
}

.unique-logo-sec {
  padding: 50px 0 100px;
  background: #f0eeee;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.unique-logo-sec:before {
  content: "";
  background: url(../src/assets/images/logo-types-bf.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  width: 437px;
  height: 377px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: green;
}

.unique-logo-sec:after {
  content: "";
  background: url(../src/assets/images/logo-types-af.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  width: 388px;
  height: 811px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}

.uniq-logo-bx {
  background: #639;
  height: 100%;
  width: 100%;
  min-height: 500px;
  position: relative;
  transform: translate(0, 0);
  transition-duration: 1s;
}

.uniq-logo-bx-co-div {
  transition-duration: 1s;
  transform: translateX(10px);
  position: relative;
  z-index: 1;
}

.uniq-logo-bx-co-div.asd {
  transition-duration: 1s;
  transform: translateX(0);
}

.uniq-logo-bx:hover {
  transform: translate(0, -10px);
}

.logo-new-innercol,
.logo-new-innercol h2 {
  display: none;
}

.lg-bx-unq-1 {
  background: linear-gradient(to bottom, #5beaff 0, #4fb1ff 100%);
}

.lg-bx-unq-2 {
  background: linear-gradient(to bottom, #5893ff 0, #596cff 100%);
}

.lg-bx-unq-3 {
  background: linear-gradient(to bottom, #aedcff 0, #5a87ff 100%);
}

.lg-bx-unq-4 {
  background: linear-gradient(to bottom, #ffac47 0, #ff428d 100%);
}

.lg-bx-unq-5 {
  background: linear-gradient(to bottom, #2de3be 0, #339b92 100%);
}

.lg-bx-unq-6 {
  background: linear-gradient(to bottom, #ffd546 0, #ff984c 100%);
}

.uniq-logo-bx h3 {
  transform: rotate(-90deg);
  width: 100%;
  text-shadow: 3.694px 4.728px 6px rgba(0, 0, 0, 0.16);
  color: #fff;
  font-weight: 700;
  position: absolute;
  bottom: 160px;
  width: 290px;
  left: -60px;
  right: 0;
}

.uniq-logo-bx img {
  margin: 0 auto;
  display: table;
}

.unique-logo-sec .hd-txt {
  text-align: center;
}

.uniq-lg-ul ul {
  list-style: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

li.uniq-logo-div-li {
  display: inline-flex;
  height: 500px;
  position: relative;
}

li.uniq-logo-div-li .uniq-lg-1 {
  width: 8%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

li.uniq-logo-div-li .uniq-lg-details {
  width: 100%;
}

.uniq-lg-1 h3 {
  position: absolute;
  width: 275px;
  transform: rotate(270deg);
  margin: auto;
  height: 45px;
  bottom: 0;
  top: 0;
  padding-top: 10px;
  padding-left: 20px;
}

.uniq-lg-ul ul {
  list-style: none;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 190px;
}

li.uniq-logo-div-li {
  display: inline-flex;
  position: relative;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

li.uniq-logo-div-li .uniq-lg-1 {
  width: 8%;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  background: #12c2e9;
  background: -webkit-linear-gradient(
    to bottom,
    var(--secondary-color),
    var(--secondary-color),
    #12c2e9
  );
  background: linear-gradient(
    to bottom,
    var(--secondary-color),
    var(--secondary-color)
  );
  padding-bottom: 0;
}

li.uniq-logo-div-li .uniq-lg-details {
  width: 68px;
  padding: 20px 0;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition-duration: 1.5s;
}

.logo-inner-u-txt p {
  padding-right: 20px;
  min-height: 60px;
  color: #fff;
}

.uniq-lg-1 h3 {
  position: absolute;
  width: 275px;
  transform: rotate(270deg);
  margin: auto;
  height: 45px;
  bottom: 0;
  top: 0;
  padding-top: 0;
  padding-left: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 1.96;
  text-shadow: 3.694px 4.728px 6px rgb(0 0 0/16%);
}

.uniq-lg-ul ul img {
  width: 100%;
  border-radius: 10px;
}

.logo-inner-u-txt:before {
  display: none;
}

li.uniq-logo-div-li.uniq-logo-div-li-1 .uniq-lg-details {
  background: linear-gradient(to right, #101e39, var(--secondary-color));
}

li.uniq-logo-div-li.uniq-logo-div-li-2 .uniq-lg-details {
  background: linear-gradient(to right, #101e39, var(--secondary-color));
}

li.uniq-logo-div-li.uniq-logo-div-li-3 .uniq-lg-details {
  background: linear-gradient(to right, #101e39, var(--secondary-color));
}

li.uniq-logo-div-li.uniq-logo-div-li-4 .uniq-lg-details {
  background: linear-gradient(to right, #101e39, var(--secondary-color));
}

li.uniq-logo-div-li.uniq-logo-div-li-5 .uniq-lg-details {
  background: linear-gradient(to right, #101e39, var(--secondary-color));
}

li.uniq-logo-div-li.uniq-logo-div-li-6 .uniq-lg-details {
  background: linear-gradient(to right, #101e39, var(--secondary-color));
}

li.uniq-logo-div-li:hover .uniq-lg-details {
  width: 800px;
}

.uniq-lg-details h4 {
  transform: rotate(270deg);
  text-align: left;
  position: absolute;
  right: 0;
  bottom: 190px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  width: 260px;
  left: -95px;
  text-shadow: 3.694px 4.728px 6px rgba(0, 0, 0, 0.16);
  text-transform: uppercase;
}

img.uniq-l-dtl {
  width: auto !important;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

li.uniq-logo-div-li.uniq-logo-div-li-1.active .uniq-lg-details,
li.uniq-logo-div-li.uniq-logo-div-li-2.active .uniq-lg-details,
li.uniq-logo-div-li.uniq-logo-div-li-3.active .uniq-lg-details,
li.uniq-logo-div-li.uniq-logo-div-li-4.active .uniq-lg-details,
li.uniq-logo-div-li.uniq-logo-div-li-5.active .uniq-lg-details,
li.uniq-logo-div-li.uniq-logo-div-li-6.active .uniq-lg-details {
  width: 800px;
}

.uniq-lg-details .bnr-btn .live-cht {
  color: #fff;
}

.uniq-lg-details .bnr-btn {
  padding-top: 0;
}
.hd-txt h2 {
  color: #231f20;
  font-size: 35px;
  font-weight: 700;
}

.hd-txt h4 {
  color: #231f20;
  font-weight: 700;
  padding: 14px 0;
}

.hd-txt p {
  color: #231f20;
  font-size: 16px;
  width: 80%;
  margin: 0 auto;
}

.hd-txt {
  padding-bottom: 55px;
}

.port-img img {
  width: 100%;
  overflow: hidden;
}

.port-img {
  overflow: hidden;
  margin-top: 20px;
}

.port-img a {
  position: relative;
}

.port-img .port-logo-inr-img1 {
  position: absolute;
  left: 28px;
  bottom: 0;
  top: 5px;
  margin: auto;
  width: 50%;
  transition-duration: 1s;
}

.port-img:hover .port-logo-inr-img1 {
  transform: scale(1.2);
  transition-duration: 1s;
}

.port-img:hover .logo-inr-img2 {
  transform: scale(1.1);
  transition-duration: 1s;
}

.port-img:hover .logo-inr-img3 {
  transform: scale(1.1);
  transition-duration: 1s;
}

.port-img:hover .logo-inr-img6 {
  transform: scale(1.1);
  transition-duration: 1s;
}

.port-logo-inr-img1.logo-inr-img2 {
  width: 44%;
  top: -70px;
  left: 0;
  right: 0;
  bottom: 0;
}

.port-logo-inr-img1.logo-inr-img3 {
  width: 70%;
  right: 0;
  left: 0;
  top: -22px;
}

.port-logo-inr-img1.logo-inr-img4 {
  width: 43%;
  right: 32px;
  left: inherit;
  top: 16px;
}

.port-logo-inr-img1.logo-inr-img5 {
  width: 30%;
  right: 22px;
  top: 0;
}

.port-logo-inr-img1.logo-inr-img6 {
  width: 68%;
  margin: auto;
  left: 22px;
  top: 43px;
}

.port-logo-inr-img1.logo-inr-img7 {
  top: 78px;
  width: 56%;
  left: 11px;
}

.port-logo-inr-img1.logo-inr-img8 {
  width: 36%;
  top: 80px;
  left: 35px;
}

.port-logo-inr-img1.logo-inr-img9 {
  width: 37%;
  top: 21px;
  left: 105px;
}

.port-logo-inr-img1.brnd-inr-img1 {
  width: 27%;
  top: -138px;
  left: 77px;
}

.port-logo-inr-img1.brnd-inr-img2 {
  width: 44%;
  left: 26px;
  top: 47px;
  bottom: 0;
  right: 0;
}

.port-logo-inr-img1.brnd-inr-img3 {
  width: 25%;
  right: 3px;
  top: -66px;
}

.port-logo-inr-img1.brnd-inr-img5 {
  width: 27%;
  top: -78px;
  left: 37px;
}

.port-logo-inr-img1.brnd-inr-img6 {
  width: 73%;
  top: 81px;
  left: 39px;
}

.port-logo-inr-img1.brnd-inr-img7 {
  left: inherit;
  right: 22px;
  top: -40px;
  width: 26%;
}

.port-logo-inr-img1.brnd-inr-img8 {
  left: inherit;
  right: 37px;
  top: 59px;
  width: 40%;
}

.port-logo-inr-img1.brnd-inr-img9 {
  top: 75px;
  width: 45%;
  right: 36px;
  left: inherit;
}

.port-img.port-web-img-st {
  padding: 50px 38px 20px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  margin-bottom: 0;
}

.port-img .port-web-st {
  width: 53%;
  height: 325px;
  position: absolute;
  padding: 0 20px;
  margin: 0 20px;
  left: 0;
  transition: all 0.3s ease-in-out;
  z-index: -1;
  box-shadow: 2px 2px 15px 3px #dadada;
}

.port-img.port-web-img-st a img {
  width: 80%;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 2px 17px 1px #3a3a3a17;
  position: relative;
  top: -30px;
}

.port-img .port-web-st.port-web-th-img2 {
  right: 0;
  left: inherit;
}

.port-img.port-web-img-st:hover img.port-web-st.port-web-th-img1 {
  transform: rotate(-12deg);
  width: 73%;
  position: absolute;
  left: 0;
}

.port-img.port-web-img-st:hover img.port-web-st.port-web-th-img2 {
  transform: rotate(11deg);
  right: 0;
  left: inherit;
}

.port-img.port-web-img-st:hover a img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.port-img.port-web-img-st img {
  border-radius: 20px;
}

.portfolio-sec .port-img img {
  border-radius: 14px;
}

.portfolio .hd-txt {
  text-align: center;
}

.port-nav-tb.port-nv-tb-inner li a {
  border-bottom: 1px solid transparent !important;
  border-radius: 0;
}

.port-nav-tb.port-nv-tb-inner .active a,
.port-nav-tb.port-nv-tb-inner li.active a:focus,
.port-nav-tb.port-nv-tb-inner li.active a:hover,
.port-nav-tb.port-nv-tb-inner li:hover a {
  color: #000 !important;
  border-bottom: 1px solid #2989ec !important;
  background: 0 0;
}

.port-nav-tb ul {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  width: 100%;
  padding: 0;
  border: 0;
}
@media only screen and (max-width: 350px) {
  .port-nav-tb ul {
    flex-wrap: wrap !important;
    width: 100%;
    display: flex;
    justify-items: center;
  }
}
.port-nav-tb li.active a,
.port-nav-tb li.active a:focus,
.port-nav-tb li.active a:hover,
.port-nav-tb li:hover a {
  color: #fff;
  background: var(--secondary-color);
  transition-duration: 0.6s;
}

.port-nav-tb li a {
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  border: 0 !important;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.port-nav-tb li {
  margin: 0 10px;
}

.portfolio-sec {
  padding: 85px 0;
  /* background-color: #d967fd */
}

.portfolio-sec .port-nav-tb li a {
  color: #fff;
  background: var(--secondary-color);
}

.portfolio-sec .hd-txt {
  text-align: center;
}

.portfolio-sec .hd-txt p {
  padding: 20px 0;
  color: rgb(0, 0, 0);
}

.portfolio-sec .hd-txt h2 {
  color: #f02082;
}

.port-nav-tb {
  padding-bottom: 35px;
}

.cta-sec {
  background: url(../src/assets/images/2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0;
  height: 350px;
  display: flex;
  align-items: center;
  margin: 70px 0;
}

.cta-img-st img {
  width: 100%;
  transition-duration: 1s;
}

.cta-txt .bnr-btn {
  display: flex;
  align-items: center;
}

.cta-txt .bnr-btn div {
  display: flex;
  flex-direction: column;
}

.cta-sec .row .col-sm-12 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-txt h2 {
  color: #231f20;
  font-size: 35px;
  font-weight: 700;
}

.cta-txt p {
  padding: 25px 0;
  font-size: 15px;
  color: #231f20;
}

.tel-btn-st {
  font-size: 16px;
  color: #000000;
  margin-left: 18px;
}

section.pricing-sec {
  padding: 50px 0 35px;
}

.pricing-custom {
  padding: 50px 70px 30px 30px;
  border-radius: 14px;
  background-color: var(--secondary-color);
}

.pricing-custom h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  margin: 0 0 15px;
}

section.pricing-sec .tab-content .pri-text {
  padding-left: 20px;
}

.pricing-custom h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 700;
  margin: 0 0 25px;
}

.pricing-custom p {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}

.price-box {
  text-align: end;
  margin-right: -45px;
}

.pric-img {
  text-align: center;
}

.pri-text h4 {
  font-size: 20px;
  font-weight: 500;
  color: #1b1b1b;
  margin: 0 0 15px;
}

.pri-text h2 {
  font-size: 36px;
  font-weight: 700;
  color: #1b1b1b;
  margin: 0 0 10px;
}

.price-main {
  padding: 40px 15px 30px;
  border-radius: 14px;
  position: relative;
  margin: 0 14px 45px;
  min-height: 500px;
  background-color: #fff;
  box-shadow: 0 11px 20px 0 rgb(0 0 0/6%);
  height: 100%;
  background-color: #D4DFF7;
  /* overflow: hidden; */
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  margin-left: -38px;
  margin-right: -38px;
  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
}

.slider-container {
  display: flex;
  overflow-x: hidden;
  scrollbar-width: thin;
  white-space: nowrap;
}

.price-main-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* gap: 3; */
}

@media screen and (width<768px) {
  .price-main-box {
    flex-direction: column;
  }
  .price-main {
    margin: 0px 0px 45px;
  }
}

.pri-ht-main h3 {
  margin: 0 0 10px;
  font-size: 28px;
  font-weight: 600;
  color: #1b1b1b;
}

.pri-ht-main p {
  font-size: 16px;
  font-weight: 500;
  color: #1b1b1b;
  margin: 0 0 35px;
}

.price-rupee-main h3 {
  margin: 0 0 10px;
  font-size: 32px;
  font-weight: 600;
  color: var(--secondary-color);
}

.plans-details-card-body {
}
.price-rupee-main p {
  font-size: 16px;
  font-weight: 400;
  color: #1b1b1b;
  margin: 0 0 40px;
  text-align: end;
}

.pric-list ul {
  padding: 0;
}

.pric-list ul li {
  list-style: none;
  margin-bottom: 20px;
}

.price-btn a.pri-btn {
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  background-color: var(--secondary-color);
  padding: 12px 10px;
  border-radius: 50px;
  margin-left: 6px;
}

.price-btn {
  text-align: center;
  /* margin-top: 40px; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.price-btn img {
  margin-left: 10px;
  display: inline-block !important;
  filter: brightness(100);
}

.price-main:hover {
  background-color: #101e39;
  border-radius: 14px;
}

.price-slider-st .slick-slide {
  padding: 0 0 40px;
}

.price-main:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 10px;
  top: 18px;
  border-radius: 14px;
  bottom: 0;
  border: 1px solid #939393;
  border-style: dashed;
}

.price-main:hover h3 {
  color: #fff;
}

.price-main:hover p {
  color: #fff;
}

.price-main:hover li {
  color: #fff;
}

.price-main:hover a.pri-btn {
  background-color: #fff;
  color: var(--secondary-color);
  font-weight: 600;
}

.price-main ul {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 292px;
}

.price-main ul::-webkit-scrollbar {
  width: 8px;
}

.price-main ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.price-main ul::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

.price-main:hover ul::-webkit-scrollbar-thumb {
  background-color: #fff;
}

.pric-bottom {
  padding-bottom: 30px;
}

.price-slider .slick-next:before {
  content: url("../src/assets/images/btn-arrow-1.png");
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 23px;
  opacity: 0.75;
  color: #000;
  font-weight: 800;
  font-size: 35px;
  font-weight: 400;
  color: var(--secondary-color);
  /* right: 30px */
}

.price-slider .slick-prev {
  transform: translate(0, -50%) scaleX(-1);
  z-index: 1111;
  left: 0;
}

.price-slider .slick-prev:before {
  content: url("../src/assets/images/btn-arrow-1.png");
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 23px;
  opacity: 0.75;
  font-weight: 800;
  font-size: 35px;
  font-weight: 400;
  color: var(--secondary-color);
  transform: rotate(-180deg);
}

/* .slick-prev:before {
    display: none
} */

.price-main:hover a.pri-btn img {
  filter: none;
}

.price-box .ser-btn {
  background: #fff;
  padding: 12px 15px;
  border-radius: 50px;
}

.price-box .ser-btn img {
  margin-left: 10px;
}

.price-combo-pck {
  padding: 50px 0;
  /* background: url(../src/assets/images/2.png); */
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.price-cmb-bx {
  background: url(../src/assets/images/logo-inner-img.gif);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  background: #f2f2f2;
  padding-top: 40px;
}

.cmb-txt-hd {
  background: var(--secondary-color);
  color: #fff;
  padding: 16px 20px;
  border-radius: 10px 10px 0 0;
  position: relative;
  border-radius: 0;
}

.cmb-txt-para {
  background: #122540;
  color: #fff;
  padding: 15px 20px;
}

.cmb-list-st {
  display: flex;
  padding-bottom: 25px;
  background: #f2f2f2;
}

.cmb-list-st ul li {
  color: #434242;
  line-height: 25px;
}

.cmb-list-st ul h4 {
  color: #080707;
  font-size: 18px;
  padding: 10px 0;
}

.cmb-list-st ul h5 {
  color: #fff;
  padding: 20px 0;
  font-size: 14px;
}

ul.cmb-ul-st-1,
ul.cmb-ul-st-2 {
  width: 37%;
  padding-top: 15px;
}

.cmb-list-st ul h5 span {
  background: #e01216;
  padding: 6px 9px;
  font-size: 20px;
}

.cmb-list-st ul {
  list-style: none;
  padding-left: 25px;
  margin: 0;
}

.cmb-list-st ul li {
  padding-left: 15px;
}

.cmb-list-st ul h4 i {
  margin-right: 5px;
  color: var(--secondary-color);
}

.cmb-list-st ul li i {
  color: var(--secondary-color);
  margin-right: 5px;
}

ul.cmb-ul-st-3 {
  text-align: right;
}

ul.cmb-ul-st-3 img {
  width: 100%;
  margin: 0 auto;
  display: table;
  text-align: center;
  position: relative;
  top: -118px;
}

.price-cmb-st {
  padding-right: 35px;
  margin-top: -30px;
}

.price-cmb-st h3 {
  color: #000;
  font-size: 25px;
  font-weight: 700;
}

.price-cmb-st p {
  font-size: 14px;
  color: #000;
}

.price-cmb-st h2 {
  font-size: 50px;
  font-weight: 700;
  color: var(--secondary-color);
  padding-top: 7px;
}

ul.cmb-ul-st-3 .price-cmb-st h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #fefe17;
  padding: 0;
  margin-bottom: 30px;
}

.cmb-ul-st-3 a {
  margin: 0;
}

.ordr-btn {
  padding: 10px 25px;
  background: #fff;
  color: #530707;
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  border-radius: 60px;
  text-transform: capitalize;
}

.cmb-txt-hd:before {
  content: "";
  background: #f2f2f2;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 155px;
}

.special-form {
  padding: 55px 0;
  background: url(../src/assets/images/sp-form-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sp-form-txt h2 {
  color: var(--secondary-color);
  font-size: 53px;
  padding: 3px 0 30px;
  font-weight: 700;
}

.sp-form-txt h4 {
  color: #271b58;
  font-weight: 600;
  font-size: 28px;
}

.sp-form-txt p {
  font-size: 16px;
}

.special-form .ftr-form-st .feildDv input {
  width: 100%;
  background: #e7e5e5;
  border: 0;
  padding: 20px 15px;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 13px;
  height: 62px;
}

.special-form .ftr-form-st .feildDv textarea {
  width: 100%;
  background: #e7e5e5;
  border: 0;
  padding: 20px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  resize: none;
  font-size: 15px;
  height: auto;
  height: 62px;
  min-height: auto;
}

.form-btn-div-st2 button {
  background: var(--secondary-color);
  border: 0;
  font-size: 17px;
  color: #fff;
  font-weight: 500;
  padding: 12px 40px;
  border-radius: 5px;
}

.form-btn-div-st2 {
  text-align: right;
}

.special-form .ftr-form-st .feildDv input:focus {
  outline: 0;
}

.special-form .ftr-form-st .feildDv input::placeholder,
.special-form .ftr-form-st .feildDv textarea::placeholder {
  color: #888888;
  font-size: 16px;
  font-weight: 400;
}

.form-btn-div-st2 button:hover {
  background: var(--secondary-color);
}

.testimonial-sec {
  padding: 50px 0 0;
}

.testimonial-sec .hd-txt p {
  text-align: left;
  padding-top: 25px;
  width: 100%;
}

.testi-box {
  background: var(--secondary-color);
  color: #fff;
  margin: 0 10px;
  padding: 60px 15px 25px;
  border-radius: 8px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  z-index: 1;
}

.testi-box:before {
  content: "";
  background: url(../src/assets/images/logo-inner-img2.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 63px;
  height: 54px;
  z-index: -1;
  margin: auto;
}

.testi-txt p {
  font-size: 14px;
  min-height: 160px;
}

.testi-img {
  margin-top: 0;
  position: absolute;
  top: -70px;
}

.testi-slider .slick-list.draggable {
  padding: 70px 0;
}

.testi-txt h4,
.testi-vide-box h4 {
  font-size: 20px;
  padding: 15px 0 5px;
  font-weight: 700;
  color: #fff;
}

.testi-vide-box h4 {
  padding: 10px 0;
  text-align: center;
}

.testi-txt h5 {
  font-size: 15px;
  font-weight: 400;
}

.testi-slider .slick-next,
.testi-slider .slick-prev {
  top: -20px;
  right: 0;
  left: inherit;
}

.testi-slider .slick-prev:before {
  content: "";
  background: url(../src/assets/images/logo-inner-img2.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 42px;
  height: 43px;
  position: absolute;
  top: 0;
  opacity: 1;
}

.testi-slider .slick-next:before {
  content: "";
  background: url(../src/assets/images/logo-inner-img2.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 42px;
  height: 43px;
  position: absolute;
  top: 0;
  opacity: 1;
}

.testi-slider .slick-next {
  right: 60px;
}

.testi-slider .slick-slide ~ .testi-slider .slick-slide .testi-box {
  background: red;
}

.testi-slider .slick-slide + .slick-slide .testi-box {
  background: #59144c;
}

.testi-slider .slick-slide + .slick-slide + .slick-slide .testi-box {
  background: #d967fd;
}

.testimonial-sec .hd-txt {
  text-align: center;
  padding-bottom: 35px;
}

.testimonial-sec .hd-txt p {
  text-align: center;
}

.footer-sec {
  padding: 50px 0 20px;
  background: #efefef;
  position: relative;
}

.footer-sec .ftr-ul-st {
  display: flex;
  align-items: flex-start;
}

.footer-sec .ftr-ul-st ul {
  width: 25%;
  padding: 0 20px;
  list-style: none;
  margin: 0;
}

.footer-sec .ftr-ul-st ul li {
  line-height: 25px;
}

.footer-sec .ftr-ul-st h3 {
  padding-bottom: 10px;
  color: #000;
  font-size: 23px;
  font-weight: 700;
  display: flex;
}

.ftr-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

img.ftr-pay {
  padding-top: 20px;
}

.ftr-bx p {
  color: #000;
  font-size: 16px;
}

.footer-sec .ftr-ul-st ul li,
.footer-sec .ftr-ul-st ul li a {
  color: #000;
  font-size: 16px;
  margin-right: 5px;
}

.ftr-reserved {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-top: 0;
}

.ftr-reserved p {
  color: #000;
  padding-top: 10px;
}

.ftr-reserved a {
  color: #000;
}

.serv-bnr .bnr-img-st {
  height: 550px;
  position: relative;
  z-index: 1;
  overflow: visible;
}

.bnr-img-st .logo-bnr-1 {
  position: absolute;
  left: -125px;
  top: inherit;
  bottom: 0;
  z-index: -10;
}

.bnr-img-st .logo-bnr-2 {
  position: absolute;
  transition-duration: 1s;
  bottom: 0;
  right: -60px;
  top: inherit;
  z-index: -1;
  transform: scale(1);
}

.logo-bnr-sec .bnr-img-st:hover .logo-bnr-2 {
  transform: scale(1.2);
  bottom: 47px;
  transition-duration: 1s;
}

.logo-inner-sec {
  padding: 85px 0;
}

.logo-inner-txt.hd-txt p {
  width: 100%;
  padding-top: 30px;
}

.logo-inner-txt.hd-txt h2 {
  font-size: 34px;
}

.logo-inner-txt.hd-txt {
  padding-bottom: 15px;
}

.logo-inner-sec .row {
  display: flex;
  align-items: center;
}

.logo-inner-img img {
  width: 100%;
  box-shadow: 0 0 59px 0 rgba(0, 0, 0, 0.05);
}

.logo-inner-img:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed #e0e0e0;
  left: 40px;
  right: 0;
  top: 60px;
  bottom: 0;
  z-index: -1;
  margin: auto;
  border-radius: 25px;
}

.logo-inner-img {
  position: relative;
}

.main-banner.logo-bnr-sec.serv-bnr {
  overflow: hidden;
}

.bnr-img-st .print-bnr-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  transform: scale(1);
  transition-duration: 1s;
  left: 0;
  right: 0;
  margin: auto;
  filter: drop-shadow(5px 5px 5px #222);
}

.bnr-img-st .print-bnr-2 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  top: 0;
  transform: scale(1);
  transition-duration: 1s;
}

.serv-bnr .bnr-img-st:hover .print-bnr-1 {
  transform: scale(1.1);
  transition-duration: 1s;
}

.serv-bnr .bnr-img-st:hover .video-bnr-1 {
  transform: scale(1.1);
  transition-duration: 1s;
  top: 50px;
}

.serv-bnr .bnr-img-st .video-bnr-1 {
  transform: scale(1);
  transition-duration: 1s;
}

.bnr-img-st .web-bnr-1 {
  top: 40px;
  margin: auto;
  left: -45px;
  transition-duration: 1s;
}

.serv-bnr .bnr-img-st .about-bnr-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.serv-bnr .bnr-img-st .about-bnr-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: scale(1);
  transition-duration: 1s;
}

.serv-bnr .bnr-img-st:hover .about-bnr-2 {
  transform: scale(1.1);
  transition-duration: 1s;
}

.serv-bnr .bnr-img-st .seo-bnr-1 {
  position: absolute;
  left: -60px;
  right: 0;
  margin: auto;
  transition-duration: 1s;
  top: 0;
  bottom: 0;
}

.serv-bnr .bnr-img-st .contact-bnr-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.serv-bnr .bnr-img-st .contact-bnr-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: -30px;
  transition-duration: 1s;
  margin: auto;
}

.serv-bnr .bnr-img-st:hover .contact-bnr-2 {
  transform: scale(1.1);
  transition-duration: 1s;
  top: 20px;
}

.contact-inner {
  padding: 90px 0 50px;
}

.contact-inner .hd-txt {
  text-align: center;
}

.contact-inner .hd-txt h2 {
  padding-bottom: 30px;
}

.contact-box {
  padding: 55px 10px 40px;
}

.img-cntct img {
  position: relative;
  top: -30px;
}

.cntct-txt h3 {
  font-size: 25px;
  font-weight: 700;
  color: #000;
  padding-bottom: 18px;
}

.cntct-txt p {
  color: #000;
  font-size: 14px;
}

.cntct-form-sec {
  padding: 70px 0;
}

.contact-text {
  color: #fff;
}

.contact-text h2 {
  font-size: 39px;
  font-weight: 700;
  margin: 0 0 20px;
}

.contact-text p {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 35px;
}

.contact-btn a.foot-btn {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-color);
  padding: 14px 30px;
  border-radius: 8px;
  background-color: #fff;
}

.contact-btn {
  border-bottom: solid 2px #271b58;
  padding-bottom: 60px;
}

.contact-btn a.foot-btn:hover {
  background-color: #271b58;
  color: #fff;
}

.contact-dress-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.adrss-text {
  color: #fff;
  margin-left: 15px;
}

.adrss-text h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.adrss-text a {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #fff;
  margin: 5px 0 0;
  display: table;
}

.contact-img-box {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: solid 2px #e3e1e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cntct-div input.form-control {
  padding: 22px 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: transparent;
}

.cntct-div textarea.form-control {
  height: 85px;
  border-radius: 6px;
  background-color: transparent;
  padding: 15px;
}

.cntct-div input#quoteSubmit {
  padding: 10px 35px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: solid 1px #271b58;
  outline: 0;
}

.button {
  text-align: center;
}

@keyframes bounceInUp {
  60%,
  75%,
  90%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

.bounceOut {
  animation-name: bounceOut;
}

.cntct-div input#quoteSubmit:hover {
  background-color: #fff;
  color: var(--secondary-color);
  border: solid 1px var(--secondary-color);
}

.cntct-div {
  /* padding: 60px; */
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  float: right;
  clear: both;
  /* margin-right: 40px; */
}

/* Styling for the previous button */
.slick-prev1 {
  content: url("../src/assets/images/btn-arrow-1.png");
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 16px;
  color: #fff;
  /* Change the color as needed */
  background-color: #333;
  /* Change the background color as needed */
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Styling for the next button */
.slick-next1 {
  content: url("../src/assets/images/btn-arrow-1.png");
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 16px;
  color: #fff;
  /* Change the color as needed */
  background-color: #333;
  /* Change the background color as needed */
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Hover effect for the buttons */
.slick-prev:hover,
.slick-next:hover {
  background-color: #555;
  /* Change the hover background color as needed */
}

.cntct-div input.form-control::placeholder {
  color: #888888;
  font-size: 14px;
  font-weight: 400;
}

.cntct-div textarea.form-control::placeholder {
  font-size: 14px;
  color: #888888;
  font-weight: 400;
}

.form-text h3 {
  font-size: 25px;
  font-weight: 500;
  color: #000;
  margin: 0 0 40px;
  text-align: center;
}

.footer-dress-box {
  margin: 40px 0 25px;
}

.bnr-img-st .port-bnr-2 {
  transition-duration: 1s;
  position: absolute;
  bottom: 40px;
  left: -75px;
  top: inherit;
}

.bnr-img-st .port-bnr-1 {
  position: absolute;
  top: 30px;
  margin: auto;
  left: 0;
  right: 0;
  z-index: -1;
}

.bnr-img-st .port-bnr-3 {
  position: absolute;
  right: -40px;
  top: inherit;
  bottom: 120px;
  z-index: -1;
}

.bnr-img-st .port-bnr-4 {
  left: -25px;
  position: absolute;
}

.port-bnr-mpge .bnr-img-st:hover .port-bnr-2,
.port-bnr-mpge .bnr-img-st:hover .port-bnr-5 {
  transform: scale(1.1);
  transition-duration: 1s;
}

.bnr-img-st .port-bnr-5 {
  position: absolute;
  top: inherit;
  z-index: -1;
  bottom: 40px;
  left: -29px;
  mix-blend-mode: multiply;
  transition-duration: 1s;
}

.bnr-img-st .packge-bnr-1 {
  /* position: absolute; */
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  transition-duration: 1s;
}

.pacakge-bnr-pg .bnr-img-st:hover .packge-bnr-1 {
  transform: scale(1.1);
  transition-duration: 1s;
}

.price-nav-tb ul {
  border: 0;
  /* text-align: center; */
  padding-left: 3%;
  padding-right: 3%;
  margin: 0 auto;
  /* display: table */
}

.price-nav-tb ul li a {
  border: 0;
  background: 0 0 !important;
  font-size: 18px;
  color: rgb(83, 79, 79);
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.price-nav-tb ul li {
  border-right: 1px solid;
}

.price-nav-tb ul li a:hover,
.price-nav-tb ul li.active a,
.price-nav-tb ul li.active a:focus,
.price-nav-tb ul li.active a:hover {
  border: 0;
  font-weight: 700;
  color: var(--secondary-color);
  background: 0 0 !important;
}

.price-nav-tb {
  background: #f3f3f3;
  padding: 15px 0;
  margin-bottom: 50px;
}

.price-nav-tb ul li:last-child {
  border: 0;
}

.get-strtd:hover {
  background: 0 0;
  border: 1px solid var(--secondary-color);
  border-radius: 21px 30px 4px 21px;
  color: var(--secondary-color);
  cursor: pointer;
}

.live-cht:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

.live-cht:hover i {
  margin-left: 10px;
}

.live-cht i {
  transition: all 0.3s ease-in-out;
}

.tel-btn-st:hover {
  color: var(--secondary-color);
}

.logo-inner-u-txt .get-strtd:hover {
  color: #fff;
  border-color: #fff;
  cursor: pointer;
}

.box11 {
  overflow: hidden;
}

.box11 {
  background: #000;
  color: #fff;
  position: relative;
}

.box11:after,
.box11:before {
  position: absolute;
  content: "";
}

.box11:before {
  width: 100%;
  height: 100%;
  background: #1f2123;
  opacity: 0.5;
  top: 100%;
  left: 0;
  z-index: 1;
  transition: all 0.35s ease-in 0.3s;
}

.box11:hover:before {
  top: 0;
  transition: all 0.35s ease-out 0s;
}

.box11:after {
  bottom: 100%;
  left: 50%;
  border-width: 200px 200px 0;
  border-style: solid;
  border-color: #2987ed96 transparent transparent;
  opacity: 0.9;
  transform: translateX(-50%);
  transition: all 0.35s ease-out 0s;
}

.box11:hover .post,
.box11:hover .title {
  transform: translate(0, -50%);
}

.box11:hover:after {
  bottom: 25%;
  transition: all 0.35s ease-in 0.2s;
}

.box11 img {
  width: 100%;
  height: auto;
}

.box11:hover img {
  opacity: 0.5;
}

.box11 .icon,
.box11 .post,
.box11 .title {
  width: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
  transition: all 0.35s ease 0.5s;
}

.box11 .box-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.box11 .title {
  padding: 0 30px;
  margin: 0;
  font-weight: 300;
  top: 45%;
}

.box11 .post,
.box14 .post {
  text-transform: capitalize;
}

.box11 .post {
  font-size: 15px;
  top: 57%;
}

.box11 .icon {
  color: #fff;
  padding: 0;
  list-style: none;
  bottom: 5px;
  text-align: center;
  top: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box11 .icon li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0 5px;
  font-size: 20px;
  color: #fff;
  opacity: 0.7;
  transition: all 0.35s ease 0.5s;
}

.box11 .icon li a:hover {
  opacity: 1;
  transition: all 0.35s ease;
}

.box11:hover .icon,
.box11:hover .post,
.box11:hover .title {
  opacity: 1;
}

@media only screen and (max-width: 990px) {
  .box11 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .box11:after {
    border-width: 500px 500px 0;
  }
}

.box11 .icon i {
  font-size: 35px;
}

.box12 .box-content i {
  font-size: 40px;
  color: #fff;
}

.box12 {
  position: relative;
  overflow: hidden;
  height: 340px;
}

.box12 .box-content,
.box12:after {
  position: absolute;
  transition: all 0.3s ease 0s;
}

.box12:after {
  content: "";
  width: 50%;
  background: rgba(28, 28, 28, 0.8);
  padding-bottom: 50%;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: rotate(0) translate(-50%, -50%);
  transform-origin: 0 0 0;
}

.box12:hover:after {
  transform: rotate(-45deg) translate(-50%, -50%);
  opacity: 1;
}

.box12 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box12 .box-content {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.box12:hover .box-content {
  opacity: 1;
}

.box12 .title {
  padding: 10px 0;
  color: #fff;
  margin: 0;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.box12 .icon {
  padding: 0;
  margin: 0;
  list-style: none;
}

.box12 .icon li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  font-size: 18px;
  color: #fff;
  margin-right: 10px;
  transition: all 0.3s ease 0s;
}

.box12 .icon li a:hover {
  border-radius: 0;
}

@media only screen and (max-width: 990px) {
  .box12 {
    margin-bottom: 20px;
  }

  .box12 {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    text-align: center;
  }

  .box12 .icon li,
  .box12 .title {
    display: inline-block;
  }

  .box12 .box-content i {
    font-size: 40px;
    color: #fff;
  }

  .terms-txt h3 {
    font-size: 25px;
    font-weight: 600;
    margin: 20px 0;
  }

  .footer-sec .ftr-ul-st ul li a img {
    filter: invert(1) brightness(0) contrast(70) grayscale(1);
  }
}

.port-logo-inr-img1.logo-inr-img4.brnd-inr-img4 {
  width: 40%;
  right: 62px;
  left: inherit;
  top: -150px;
}

.footer-sec .ftr-ul-st ul li a i {
  font-size: 25px;
}

.footer-sec ul.social-ul-st.socil-box li {
  background-color: #fff;
}

.footer-sec ul.social-ul-st.socil-box li a {
  width: 26px;
  height: 30px;
  line-height: 26px;
  padding: 2px;
  text-align: center;
  color: #000;
}

.footer-sec ul.social-ul-st.socil-box li {
  border-radius: 4px;
}

img.ftr-pay {
  width: 100%;
}

.ftr-bx img {
  margin: 10px 0;
}

.sp-form-btn {
  /* display: flex; */
  align-items: center;
}

.form-btn-div-cht {
}

.form-btn-div-cht a {
  border-radius: 21px 30px 4px 21px;
  background: #271b58;
  padding: 8px 15px;
  font-size: 15px;
  color: #fff;
  margin-left: 15px;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.form-btn-div-tel {
  margin-left: 20px;
}

.ftr-img {
  position: absolute;
  right: 0;
  bottom: -10px;
}

.ftr-img img {
  width: 88%;
}

.view-dtls-inner-sec {
  padding: 50px 0;
}

img.web-bnr-1:hover {
  transform: scale(1.1);
  transition-duration: 1s;
}

.serv-bnr .bnr-img-st .seo-bnr-1:hover {
  transform: scale(1.1);
  transition-duration: 1s;
}

.logo-inner-img img:hover {
  transform: scale(1.05);
  transition-duration: 1s;
}

.logo-inner-img img {
  transition-duration: 1s;
}

.cta-img-st img:hover {
  transition-duration: 1s;
  transform: scale(1.05);
}

.cntct-txt a {
  color: #222;
}

.cntct-txt {
  text-align: center;
}

.img-cntct {
  text-align: center;
}

.view-dtl-bx-pck .price-main {
  min-height: 485px;
}

img.bnr1_mm {
  position: absolute;
  z-index: 11;
  left: 35px;
  top: 276px;
  transition: all 0.8s ease-in-out;
}

img.bnr2_mm {
  position: absolute;
  z-index: 1;
  right: 29px;
  top: 268px;
  transition: all 0.8s ease-in-out;
}

img.bnr3_mm {
  position: absolute;
  z-index: 1;
  right: 108px;
  top: 169px;
  left: 0;
  margin: auto;
  transition: all 0.8s ease-in-out;
}

img.bnr3_mm:hover,
img.bnr2_mm:hover,
img.bnr1_mm:hover {
  transform: scale(1.3);
  transition: all 0.8s ease-in-out;
  z-index: 999;
}

section.terms-sec {
  padding: 50px 0;
}

section.terms-sec h3 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.socil-box {
  align-items: center;
}

ul.social-ul-st.socil-box li img {
  width: 30px;
}

ul.social-ul-st.socil-box li {
  display: inline-flex;
}

.social-text h3 {
  margin-left: 60px;
}

.ftr-trms {
  margin-left: 90px;
}

.testi-vide-box {
  background: #d967fd;
  color: #fff;
  padding: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  z-index: 1;
  margin: 70px 0;
}

.testi-vide-box img {
  width: 80%;
  border-radius: 8px;
  position: relative;
  margin: 22px auto 0;
  display: table;
}

.testi-vide-box:before {
  content: "";
  background: url(../src/assets/images/logo-inner-img2.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  width: 63px;
  height: 54px;
  z-index: -1;
  margin: auto;
}

.play-ico-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-ico-div i {
  font-size: 40px;
  color: #fff;
  position: relative;
  top: -20px;
}

@media only screen and (max-width: 580px) {
  .logo {
    width: 69%;
    margin: 0;
  }

  .top-header {
    padding: 10px 0;
    display: none;
  }
}

.tawk-custom-color {
  background: #d967fd !important;
}

.section4 {
  padding: 80px 0 100px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background: rgb(42 121 250);
}

body {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

section {
  position: relative;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.3s;
}

.logo {
  width: 100%;
  padding: 9px 0;
}

.logo img {
  width: 100%;
  max-width: 105px;
}

.menu {
  display: none;
}

.pricing-page-menu {
  display: none;
}

.pricing-page-goto-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pricing-page-goto-tabs li a {
  border: 0;
  border-radius: 0;
  padding: 10px 15px;
  color: #d967fd;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s;
  margin: 0 5px;
  display: block;
  text-decoration: none;
  font-size: 14px;
}

.pricing-page-goto-tabs li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 4px);
  height: 5px;
  background: #d7d7d7;
  transform: translateX(-50%);
  transition: all 0.3s;
}

.pricing-page-goto-tabs li a:hover {
  background: #d967fd;
  color: #fff;
}

.pricing-page-goto-tabs li a:hover:before {
  background: #d967fd;
}

.scrolled {
  background: #fff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
}

span.menu-hover-effect {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color) 50%,
    #271b58 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.6s ease;
}

span.menu-h-effect {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color) 50%,
    #fff 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.6s ease;
}

span.menu-hover-effect:hover {
  background-position: 0 100%;
}

span.menu-h-effect:hover {
  background-position: 0 100%;
}

.black-logo {
  display: none;
}

.scrolled .white-logo {
  display: none;
}

.scrolled .black-logo {
  display: initial;
}

.white-header {
  background: #fff;
  border-bottom: 1px solid #ddd;
}

.white-header .menu > ul > li > a {
  color: #000;
}

.white-header .menu > ul > li > a > i {
  color: #d967fd;
}

.white-header .menu > ul > li > a > i:before {
  color: #d967fd;
}

.white-header .white-logo {
  display: none;
}

.white-header .black-logo {
  display: initial;
}

.position-rel-container {
  position: relative;
  height: 100%;
}

.pos-rel {
  position: relative;
}

.pos-initial {
  position: initial;
}

.home-banner {
  width: 100%;
  height: 100vh;
  background: url("../src/assets/images/home-banner1.jpg") no-repeat center
    center;
  background-size: cover;
  position: relative;
  min-height: 650px;
}

.home-video-banner {
  background: none;
}

.home-banner-video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-banner-video video {
  min-width: 100%;
  min-height: 100%;
  filter: grayscale(100%);
}

.inner-banner {
  width: 100%;
  height: 100vh;
  position: relative;
  min-height: 650px;
}

/* .lb-banner {
    background: url("../images/logo-branding-bg.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .web-banner {
    background: url("../images/website-bg.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .mobile-app-banner {
    background: url("../images/mobileapp-bg.jpg")
      no-repeat center center;
    background-size: cover;
    overflow: hidden;
  }
  
  .video-banner {
    background: url("../images/video-bg.jpg")
      no-repeat center center;
    background-size: cover;
  } */

/*.dm-banner {
      background: url('../images/dm-bg.jpg') no-repeat center center;
      background-size: cover;
  }*/

/* .smm-banner {
    background: url("../images/smm-social-media-banner.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .fb-banner {
    background: url("../images/facebook-facebook.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .instagram-banner {
    background: url("../images/instagram-instagram-banner.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .twitter-banner {
    background: url("../images/twitter-twitter.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .linkedin-banner {
    background: url("../images/linkedin-banner.jpg")
      no-repeat center center;
    background-size: cover;
  }
   */
.dm-banner .absolute-center,
.smm-banner .absolute-center,
.fb-banner .absolute-center,
.instagram-banner .absolute-center,
.twitter-banner .absolute-center {
  top: 51%;
}

.inner-banner-img {
  display: none;
}

.contact-banner {
  /* background: url("../images/contact-banner.jpg") */
  /* no-repeat center center; */
  background-size: cover;
  height: 50vh;
  min-height: 500px;
}

.pricing-banner {
  /* background: url("../images/pricing-bg.jpg") */
  /* no-repeat center center; */
  background-size: cover;
  height: 50vh;
  min-height: 500px;
}

.about-banner {
  background: url("../src/assets/images/banner_2.jpg") no-repeat center center;
  background-size: cover;
  height: 50vh;
  min-height: 500px;
}

.blog-banner {
  /* background: url("../images/about-banner.jpg") */
  /* no-repeat center center; */
  background-size: cover;
  height: 50vh;
  min-height: 500px;
}

.portfolio-banner {
  /* background: url(../images/contact-banner.jpg) no-repeat center center; */
  background-size: cover;
  height: 50vh;
  min-height: 500px;
}

.portfolio-banner-bg {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
}

.portfolio-banner-svg {
  display: none;
}

.black-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.banner-line {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.exta-div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.banner-line:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
  transform: translateX(-50%);
}

.banner-line .exta-div:before {
  content: "";
  position: absolute;
  left: 18%;
  top: 0;
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.banner-line .exta-div:after {
  content: "";
  position: absolute;
  right: 18%;
  top: 0;
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-bottom {
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-bottom-link {
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
}

.home-banner .absolute-center {
  top: 44%;
}

.banner-heading {
  text-align: center;
  width: 100%;
  color: #fff;
}

.banner-heading h1 {
  text-transform: capitalize;
  margin: 0 0 30px;
  /* font-size: 35px; */
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.banner-heading p,
.inner-banner-heading p,
.banner-heading h6,
.inner-banner-heading h6 {
  margin: 0 0 20px;
  /* font-size: 23px; */
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.banner-heading p br,
.inner-banner-heading p br,
.banner-heading h6 br,
.inner-banner-heading h6 br {
  display: none;
}

.inner-banner-heading {
  width: 100%;
  color: #fff;
  text-align: center;
}

.inner-banner-heading h1 {
  margin: 0 0 30px;
  font-size: 35px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.inner-banner-heading .link-area {
  margin-top: 20px;
}

.banner-heading h1 br,
.inner-banner-heading h1 br {
  display: none;
}

.home-banner-form {
  display: inline-block;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.home-banner-form form {
  position: relative;
}

.home-banner-form form::before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  background: url("../src/assets/images/pencil.png") no-repeat center center;
  background-size: contain;
  left: 15px;
  /* top: 30px; */
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .home-banner-form form::before {
    top: 32px;
  }
}

/* Adjustments for small screens (e.g., smartphones) */
@media screen and (max-width: 767px) {
  .home-banner-form form::before {
    top: 26px;
  }
}

.home-banner-input {
  float: left;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 13px 15px 13px 45px;
  /* float: left; */
  font-weight: 700;
  outline: 0;
  border: 0;
  color: #ffffff;
  border-radius: 0;
  border: 2px dashed #ffffff;
  margin-right: 8px;
  /* transition: all 0.3s; */
}

.home-banner-input::placeholder {
  color: #989898;
}

.home-banner-submit {
  position: relative;
  padding: 15px 40px;
  border: 0;
  color: #fff;
  font-weight: 800;
  background: var(--secondary-color);
  outline: 0;
  overflow: hidden;
  border-radius: 0;
  border: 0;
  transition: all 0.3s;
  z-index: 1;
  /* width: 100%; */
  margin-top: 10px;
}

.home-banner-submit:focus {
  outline: 0;
}

button.home-banner-submit:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 20px;
  position: absolute;
  left: -100%;
  transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: -1;
}

button.home-banner-submit:hover:before {
  left: 100%;
}

button.home-banner-submit span {
  background: linear-gradient(to right, #ddd, #ddd 50%, #fff 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.6s ease;
}

button.home-banner-submit:hover span {
  background-position: 0 100%;
}

.home-banner > .container,
.inner-banner > .container {
  z-index: 1;
}

.banner-bottom-carousel {
  text-align: center;
}

.banner-bottom-carousel.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

.banner-bottom-carousel img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}

.banner-goto-link {
  text-align: center;
}

.banner-goto-link a {
  display: inline-block;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

#banner-scroll-down {
  cursor: pointer;
}

.scroll-to-field {
  cursor: pointer;
}

.banner-goto-link a p {
  margin: 0;
  color: #fff;
  font-style: italic;
}

.banner-goto-link a p img {
  height: 19px;
  margin-right: 7px;
  margin-top: -3px;
}

.banner-goto-link a i {
  font-size: 30px;
  margin-top: 5px;
}

.white-sec {
  padding: 45px 0;
  width: 100%;
}

.green-sec {
  padding: 45px 0;
  width: 100%;
  background: #e7f6f1;
}

.grey-sec {
  padding: 45px 0;
  width: 100%;
  background: #eee;
}

.light-grey-sec {
  padding: 45px 0;
  width: 100%;
  background: #f7f7f7;
}

.section-heading {
  text-align: center;
  width: 100%;
}

.section-heading h5 {
  position: relative;
  padding: 0 50px;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
}

.section-heading h5:before {
  content: "";
  position: absolute;
  left: 0;
  top: 40%;
  width: 45px;
  height: 10px;
  transform: translateY(-50%);
  background: url(../src/assets/images/arrow_1.png) no-repeat right center;
  background-size: contain;
}

.section-heading h5:after {
  content: "";
  position: absolute;
  right: 0;
  top: 40%;
  width: 45px;
  height: 10px;
  transform: translateY(-50%) scaleX(-1);
  background: url(../src/assets/images/arrow_1.png) no-repeat right center;
  background-size: contain;
}

.section-heading h1,
.section-heading h2 {
  /* font-weight: 700;
  font-size: 25px;
  color: #000;
  padding-top: 3px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  margin: 0 0 30px; */
}

.section-heading h1 br,
.section-heading h2 br {
  display: none;
}

.section-heading p,
.section-heading h6 {
  font-weight: 300;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  line-height: 1.15;
  padding-top: 5px;
  margin-bottom: 1rem;
  text-align: left;
}

.section-heading p.simple-p {
  /* font-family: "Nunito Sans", sans-serif;
  line-height: 1.4;
  padding-right: 30px;
  padding-top: 0;
  padding-right: 0;
  font-size: 16px; */
}

.section-heading p br,
.section-heading h6 br {
  display: none;
}

.section-heading.left-heading h5:after {
  display: initial;
}

.left-heading {
  text-align: center;
}

.section-heading .link-area {
  margin-top: 30px;
  text-align: center;
}

.portfolio-carousel-sec .link-area {
  margin-top: 45px;
  text-align: center;
}

.portfolio-carousel {
  padding-top: 14px;
}

.inner-portfolio .link-area {
  margin-top: 45px;
  text-align: center;
}

.link-area a.simple-a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--secondary-color);
  padding: 10px 0;
  margin-right: 15px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
}

.link-area a.simple-a i {
  font-size: 24px;
  margin-right: 10px;
  position: relative;
  bottom: -4px;
}

.link-area a.hover-effect {
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 30px;
  z-index: 2;
  font-weight: 700;
}

.link-area a.hover-effect:before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  z-index: 2;
  transition: all 0.3s;
}

.inner-banner .link-area {
  text-align: center;
}

.inner-banner .link-area a.hover-effect:before {
  border: 2px solid #fff;
}

.inner-banner .link-area a.simple-a {
  color: #fff;
  margin-right: 0px;
  margin-left: 30px;
}

.inner-banner .link-area a.simple-a img {
  width: 27px;
  margin-right: 10px;
  position: relative;
  bottom: 2px;
}

.inner-banner .link-area a.simple-a:nth-child(3) {
  margin-left: 0;
}

.link-area a.hover-effect:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 100%;
  height: 100%;
  background: var(--secondary-color);
  z-index: -1;
  transition: all 0.3s;
}

span.flash-glow-effect {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s;
}

span.flash-glow-effect:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 15px;
  position: absolute;
  left: -100%;
  transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

a:hover span.flash-glow-effect:before,
button:hover span.flash-glow-effect:before,
div:hover > span.flash-glow-effect:before {
  left: 100%;
}

.link-area a.hover-effect:hover:before {
  left: 0;
  top: 0;
  border: 2px solid var(--secondary-color);
}

.link-area a.hover-effect:hover:after {
  left: 0;
  top: 0;
}

.second-sec-hover {
  position: relative;
}

.second-sec-hover img {
  position: relative;
  z-index: 1;
}

.second-sec-hover:before {
  content: "";
  position: absolute;
  left: 7px;
  top: 7px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #d967fd;
  transition: all 0.3s;
}

.second-sec-hover:hover:before {
  left: 0;
  top: 0;
}

.about-detail-sec {
  padding-top: 20px;
}

.about-detail-sec:first-child {
  margin-top: 35px;
}

.about-detail-img {
  width: 70px;
  height: 100%;
  padding-right: 10px;
  float: left;
}

.about-detail-img.diff-img {
  padding-right: 5px;
}

.about-detail-img.diff-img-0 {
  padding-right: 0;
}

.about-detail-img img {
  width: 100%;
}

.about-detail-right {
  float: left;
  width: calc(100% - 70px);
  padding-left: 10px;
}

.about-detail-right h3 {
  font-size: 19px;
  font-weight: 700;
  color: #000;
  font-family: "Poppins", sans-serif;
}

.green-sec-heading {
  padding-bottom: 10px;
  text-align: center;
}

.green-sec-heading h3 {
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

.green-sec-heading h3 br {
  display: none;
}

.green-sec-heading form {
  position: relative;
}

.green-sec-heading form:before {
  content: "";
  position: absolute;
  height: 17px;
  width: 18px;
  /* background: url(../images/images-pencil-white.png) no-repeat center center; */
  background-size: contain;
  top: 28px;
  left: 15px;
  transform: translateY(-50%);
}

.about-detail-right p {
  line-height: 1.15;
  margin-bottom: 9px;
  font-size: 16px;
}

.about-detail-right a {
  color: #d967fd;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
}

.about-detail-right a i {
  font-weight: 900;
  position: relative;
  left: 0;
  transition: all 0.3s;
}

.about-detail-right a:hover i {
  left: 5px;
}

.grey-badge {
  position: relative;
  background: #eee;
  border-top: 2px solid #d7d7d7;
  border-bottom: 2px solid #d7d7d7;
}

.nav-tabs {
  border-bottom: 0;
}

.service-tabs {
  margin: 0 -15px;
}

.service-tabs ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.service-tabs ul li img {
  width: 100%;
  max-width: 35px;
}

.service-tabs ul li i {
  font-size: 35px;
}

.service-tabs ul li i:before {
  color: #666666;
}

.service-tabs .nav-tabs .nav-item {
  width: 25%;
}

.service-tabs .nav-tabs .nav-link {
  padding: 10px 7px 7px 7px;
  margin-bottom: -2px;
  position: relative;
  color: #4b4b4b;
  text-transform: uppercase;
  font-weight: 800;
  /* font-size: 14px; */
  border: 2px solid transparent;
  transition: all 0.3s;
  z-index: 2;
}

.service-tabs .nav-tabs .nav-link.active {
  padding-top: 17px;
  margin-top: -10px;
  color: var(--secondary-color);
  border: 2px solid #d7d7d7;
  border-top: 5px solid #d7d7d7;
  border-bottom: 3px solid #fff;
  border-radius: 0;
}

.service-tabs .nav-tabs .nav-link.active i:before {
  color: var(--secondary-color);
}

.service-tabs .nav-tabs .nav-link:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -5px;
  background: url(../src/assets/images/services-top.png) no-repeat top center;
  background-size: contain;
  width: 30px;
  height: 15px;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.3s;
}

.service-tabs .nav-tabs .nav-link.active:before {
  opacity: 1;
}

.service-tabs .nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  right: -6px;
  top: 50%;
  width: 3px;
  height: 80%;
  opacity: 1;
  background: #d7d7d7;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.service-tabs .nav-tabs .nav-item:last-child .nav-link:after {
  display: none;
}

.service-tabs .nav-tabs .nav-link.active:after {
  opacity: 0;
}

.service-tabs .nav-tabs .nav-item:first-child .nav-link.active {
  border-left: 0;
  padding-left: 9px;
}

.service-tabs .nav-tabs .nav-item:last-child .nav-link.active {
  border-right: 0;
  padding-right: 9px;
}

.service-tabs .nav-tabs .nav-link p {
  font-size: 10px;
  margin: 7px 0 0 0;
  color: #6f6f6f;
}

.green-img {
  display: none;
}

.service-tabs .nav-tabs .nav-link.active .dark-img {
  display: none;
}

.service-tabs .nav-tabs .nav-link.active .green-img {
  display: initial;
}

.service-tab-content {
  padding: 45px 0;
}

.service-tab-content img {
  width: 100%;
  margin-top: 30px;
}

.service-tab-content .section-heading.left-heading {
  padding-left: 0px;
  text-align: left;
}

.service-tab-content .section-heading.left-heading h5:after {
  display: none;
}

.pricing-sec .link-area {
  margin-top: 45px;
  text-align: center;
}

.pricing-tabs ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.pricing-tabs .nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
  padding: 10px 15px;
  color: var(--secondary-color);
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s;
  margin: 0 7px;
  margin-bottom: 10px;
}

.pricing-tabs .nav-tabs .nav-link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 4px);
  height: 5px;
  background: #d7d7d7;
  transform: translateX(-50%);
  transition: all 0.3s;
}

.pricing-tabs .nav-tabs .nav-link.active {
  background: var(--secondary-color);
  color: #fff;
}

.pricing-tabs .nav-tabs .nav-link.active:before {
  background: var(--secondary-color);
}

.pricing-content {
  padding: 35px 10px 0 10px;
}

.single-pricing {
  position: relative;
  margin: 0 -10px;
  margin-top: 15px;
  background: #fff;
  padding: 30px 20px 15px 20px;
  text-align: center;
  top: 0;
  transition: all 0.3s;
}

.single-pricing:hover {
  top: -10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.pricing-badges {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pricing-badges img {
  width: 70%;
}

.big-pricing {
  margin-top: 0;
}

.big-pricing .pricing-link {
  padding-bottom: 30px;
  border: 0;
}

.recommended-price:before {
  content: "RECOMMENDED";
  position: absolute;
  top: 0;
  right: 0px;
  background: var(--secondary-color);
  padding: 6px 7px 4px 23px;
  font-size: 9px;
  transform: rotate(90deg) translate(100%, 100%);
  transform-origin: top right;
  color: #fff;
  line-height: 1;
}

.black-friday-banner-bg {
  /* background: url("../images/images-black-friday-banner-bg.jpg") center center */
  /* no-repeat; */
  background-size: cover;
}

.black-friday-banner-bg .black-overlay {
  display: none;
}

.black-friday-sale .single-pricing {
  padding-top: 45px;
}

.black-friday-sale .single-pricing:after {
  content: "BLACK FRIDAY OFFER";
  position: absolute;
  top: 0;
  left: 50%;
  background: #000;
  padding: 5px 15px 5px 15px;
  font-size: 9px;
  transform: translate(-50%, 0);
  color: #fff;
  line-height: 1;
}

sup.cut-price {
  font-size: 35%;
  color: #000;
  text-decoration: line-through;
  top: -1.5em;
}

.pricing-black-friday-banner {
  display: none;
}

.big-pricing .col-md-3 > .price-head > h6 {
  margin-top: 0;
}

.price-head h4 {
  font-weight: 700;
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  color: #000;
  margin-bottom: 5px;
}

.big-pkg-head h4 {
  font-size: 30px;
  padding-bottom: 20px;
}

.price-head h6 {
  font-weight: 700;
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  color: #d967fd;
  margin-bottom: 0;
}

.price-head p {
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  font-size: 14px;
  margin: 0;
}

.price-head p span {
  font-size: 18px;
  text-transform: capitalize;
}

.pricing-link {
  padding: 30px 0;
  border-bottom: 1px solid #d7d7d7;
}

.pricing-link a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 30px;
  z-index: 2;
  font-weight: 700;
}

.pricing-link a:before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #000;
  z-index: 2;
  transition: all 0.3s;
}

.pricing-link a:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--secondary-color);
  z-index: -1;
  transition: all 0.3s;
}

.pricing-link a:hover:before {
  left: 0;
  top: 0;
  border: 2px solid var(--secondary-color);
}

.pricing-link a:hover:after {
  left: 0;
  top: 0;
}

.pricing-detail {
  padding: 15px 0;
}

.pricing-detail.p-mob-0 {
  padding: 0;
}

.big-pricing .col-md-3:last-child .pricing-detail.p-mob-0 {
  padding-bottom: 20px;
}

.pricing-detail ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.multi-price-ul ul {
  min-height: 290px;
  max-height: 290px;
  overflow: auto;
}

.pricing-detail ul li {
  text-align: center;
  width: 100%;
  padding: 3px 0;
  font-weight: 700;
  font-size: 14px;
  color: #000;
}

.pricing-detail ul li.multi-price-heading {
  font-weight: 900;
  margin-top: 7px;
}

.pricing-detail ul li.multi-price-heading:first-child {
  margin-top: 0px;
}

.big-pricing .fullpricehead {
  text-align: center;
  padding-left: 0;
}

.big-pricing .pricing-detail ul li {
  text-align: center;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
}

.big-pricing .pricing-detail ul li:last-child {
  border-bottom: 1px solid #ddd;
}

.big-pricing .pricing-detail ul li i {
  margin-left: 0;
}

.big-pricing .fullpricehead h4 {
  margin-bottom: 0;
}

.big-pricing .price-head + .pricing-detail ul li:last-child {
  border-bottom: 0;
}

.pricing-detail ul li i.fa-check {
  color: var(--secondary-color);
}

.pricing-detail ul li i.fa-times {
  color: red;
}

.pricing-foot ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.pricing-foot ul:before {
  content: "";
  position: absolute;
  left: 51.5%;
  top: 50%;
  width: 2px;
  height: 75%;
  background: #d7d7d7;
  transform: translateY(-50%);
}

.pricing-foot ul li {
  text-align: center;
  width: 50%;
  padding: 3px 0;
  font-weight: 800;
  font-size: 10px;
  color: #000;
  float: left;
}

.pricing-foot ul li a {
  width: 100%;
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.pricing-foot ul li a i {
  color: var(--secondary-color);
  margin-right: 3px;
  font-size: 13px;
  position: relative;
  bottom: -2px;
}

.pricing-foot ul li a i.icon-icons-01:before {
  color: var(--secondary-color);
}

.inner-mutli-pricing-tabs {
  padding-bottom: 15px;
}

.inner-mutli-pricing-tabs ul {
  width: 100%;
  min-height: auto;
  max-height: auto;
  overflow: initial;
  background: #d4d4d4;
  border: 4px solid #d4d4d4;
}

.inner-mutli-pricing-tabs ul li {
  width: auto;
  padding: 0;
}

.inner-mutli-pricing-tabs .nav-tabs .nav-link {
  position: relative;
  width: 100%;
  padding: 5px 0;
  border-radius: 0;
  border: 0;
  font-size: 11px;
  color: #000;
}

.inner-mutli-pricing-tabs .nav-tabs .nav-link.active {
  color: #fff;
  background: var(--secondary-color);
  border: 0;
  padding: 4.5px 0;
}

.inner-mutli-pricing-tabs .nav-tabs .nav-link.active:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 10px;
  height: 10px;
  background: var(--secondary-color);
  border-bottom: 3px solid #d4d4d4;
  border-right: 3px solid #d4d4d4;
  transform: translate(-50%, 70%) rotate(45deg);
}

.pricing-content .tab-pane .col-lg-3:first-child .single-pricing {
  margin-top: 0;
}

#logo-branding-price .inner-mutli-pricing-tabs ul li {
  width: 33.333%;
}

#logo-web-branding-price .inner-mutli-pricing-tabs ul li,
#logo-branding-website-price .inner-mutli-pricing-tabs ul li {
  width: 25%;
}

.stats-sec {
  padding: 45px 0;
  width: 100%;
  background: var(--secondary-color);
  color: #fff;
}

.stats-sec .section-heading h1,
.stats-sec .section-heading h2 {
  color: #fff;
}

.stats-sec .section-heading h5:before {
  /* background: url(../images/images-arrow-w.png) no-repeat left center; */
  background-size: contain;
}

.stats-sec .section-heading h5:after {
  /* background: url(../images/images-arrow-w.png) no-repeat right center; */
  background-size: contain;
}

.stats-sec .link-area {
  text-align: center;
  padding-top: 15px;
}

.stats-sec .link-area a.simple-a {
  margin: 0;
  border: 2px solid #fff;
  color: #fff;
  padding: 10px 30px;
  font-weight: 800;
  transition: all 0.3s;
}

.stats-sec .link-area a.simple-a:hover {
  background: #fff;
  color: var(--secondary-color);
}

.single-stat {
  margin: 0 -10px;
  padding: 25px 15px;
  text-align: center;
  /* margin-top: 15px; */
  background: #fff;
  position: relative;
}

.single-stat:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  transition: all 0.3s;
}

.single-stat:hover:after {
  top: 0;
  left: 0;
}

.single-stat img {
  width: 100%;
  max-width: 65px;
  margin-bottom: 10px;
}

.single-stat img.diffrent-img {
  max-width: 90px;
  height: 90px;
  margin: -10px 0;
}

.stat-head {
  margin: 0;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.1;
  color: #000;
}

.stat-det {
  margin: 0;
  color: #4b4b4b;
}

.trusted-link-area {
  margin-top: 40px;
  text-align: center;
  width: 100%;
}

.single-testimonial {
  background: #fff;
  color: #000;
  padding: 30px;
  margin: 15px 0 0 0;
  transition: all 0.3s;
  position: relative;
  top: 0;
}

.single-testimonial:hover {
  background: #d967fd;
  color: #fff;
  top: -5px;
}

.single-testimonial p {
  font-size: 16px;
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
}

.single-testimonial:before {
  content: "\e901";
  font-family: "icomoon";
  position: absolute;
  bottom: 30px;
  right: 30px;
  font-weight: 900;
  font-size: 40px;
  line-height: 1;
  color: #d967fd;
  transition: all 0.3s;
}

.single-testimonial:hover:before {
  color: #fff;
}

.web-testimonial {
  position: relative;
  color: #000;
  padding: 30px 15px;
  transition: all 0.3s;
}

.web-testimonial.center-div {
  top: 50%;
  transform: translateY(-50%);
}

.video-testimonial .web-testimonial.center-div {
  transform: translateY(calc(-50% - 15px));
}

.web-testimonial p {
  font-size: 16px;
  font-style: italic;
}

.web-testimonial h6 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-top: 18px;
  color: #000;
}

.web-testimonial:before {
  content: "\e901";
  font-family: "icomoon";
  position: absolute;
  bottom: 30px;
  right: 30px;
  font-weight: 900;
  font-size: 60px;
  line-height: 1;
  color: rgba(25, 164, 125, 0.2);
  transition: all 0.3s;
}

.mobile-app-testimonial .web-testimonial {
  top: 45%;
  transform: translateY(-50%);
}

.testimonial-detail {
  overflow: hidden;
}

.testimonial-img {
  width: 60px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
}

.testimonial-img img {
  width: 100%;
}

.testimonial-detail-inner {
  float: left;
  width: calc(100% - 75px);
}

.testimonial-stars i {
  color: #f9d549;
}

p.testimonial-name {
  margin: 0;
  font-size: 15px;
  font-weight: 900;
  line-height: 1;
  margin-top: 3px;
}

p.testimonial-occupation {
  margin: 0;
  font-size: 12px;
  line-height: 1;
  margin-top: 2px;
}

.video-testimonial-hover {
  position: relative;
  margin-left: 0;
  margin-bottom: 15px;
}

.video-testimonial-hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}

.video-testimonial-hover .work-video-popup img {
  width: auto;
}

.video-testimonial-hover:before,
.video-testimonial-hover:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.video-testimonial-hover:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.video-testimonial .col-lg-6 {
  padding-left: 30px;
  padding-right: 30px;
}

.single-awards {
  width: 100%;
  padding: 0 15px;
  margin-top: 15px;
  text-align: center;
}

.single-awards img {
  max-height: 56px;
}

.white-sec .col-lg-3:first-child .single-awards {
  margin-top: 0;
}

.owl-carousel .owl-item .single-awards img {
  width: auto;
  display: initial;
}

.awards-heading {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin: 0;
  margin-top: 15px;
  line-height: 1.15;
}

.awards-detail {
  font-size: 15px;
  margin: 0;
  margin-top: 10px;
  font-weight: 600;
}

.awards-foot-img {
  width: 32px;
  margin-right: 20px;
  float: left;
}

.awards-foot-img img {
  width: 100%;
}

.awards-foot {
  display: inline-block;
  margin: 0 auto;
  margin-top: 30px;
}

.awards-foot-detail {
  float: left;
  text-align: left;
  color: rgb(147, 145, 165);
}

.award-name {
  font-weight: 800;
  font-size: 16px;
  line-height: 1;
  margin: 0;
}

.awards-date {
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  margin: 0;
  margin-top: 7px;
}

.single-blog {
  margin: 0 -7px;
}

.blog-list-sec .single-blog {
  margin: 0;
  margin-bottom: 30px;
}

.blog-pagination {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.blog-pagination .pagination {
  margin: 0;
}

.blog-pagination .page-item .page-link,
.blog-pagination .page-item .page-link:focus {
  color: #4b4b4b;
  box-shadow: none;
  border-radius: 0;
  margin: 0 4px;
  background: transparent;
  border: 1px solid #cccccc;
}

.blog-pagination .page-item.active .page-link {
  background: #d967fd;
  border-color: #d967fd;
  color: #fff;
}

.blog-list-sec .col-12:last-child .single-blog {
  margin-bottom: 0;
}

.blog-detail-page,
.top-pad-page {
  padding-top: 125px;
}

.blog-detail-page .blog-detail-area p img {
  width: 100%;
  max-width: 470px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.blog-detail-page-detail {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.blog-detail-page-detail p {
  font-size: 10px;
  padding: 0 10px;
  color: #888888;
  float: left;
}

.blog-detail-page-detail img {
  width: auto;
  height: 24px;
  border-radius: 50%;
  margin-top: -4px;
  margin-right: 3px;
}

.blog-detail-page-detail p span {
  text-transform: uppercase;
}

.blog-detail-page .blog-detail-area h3,
.blog-detail-page .blog-detail-area h3:hover {
  margin-top: 15px;
  font-size: 20px;
  margin-bottom: 5px;
  color: #000;
}

.blog-detail-page .blog-detail-area h3:first-child {
  margin-top: 0;
}

.blog-detail-page-detail p + p {
  padding-top: 2px;
  border-left: 1px solid #888888;
}

.blog-detail-page .section-heading a {
  text-decoration: none;
}

.blog-detail-page .section-heading a h1,
.blog-detail-page .section-heading a h2 {
  transition: all 0.3s;
}

.blog-detail-page .section-heading a h1:hover,
.blog-detail-page .section-heading a h2:hover {
  color: #d967fd;
}

.blog-detail-page .blog-detail-area {
  padding: 30px;
}

.blog-detail-page .contact-form-submit-area:after {
  z-index: 0;
}

.blog-comment-sec {
  padding: 30px 37px 40px 37px;
  background: #fff;
  margin: 55px -7px 0 -7px;
}

.tags-share-area {
  display: flex;
  margin-top: 25px;
}

.blog-right-detail .tags-share-area {
  margin-top: 0px;
}

.tags-share-area + .tags-share-area {
  margin-top: 10px;
}

.tags-share-area span {
  min-width: 73px;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 5px;
}

.tags-share-area ul {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.tags-share-area ul li {
  margin: 0 3px;
}

.blog-right-detail .tags-share-area ul li {
  flex-grow: 1;
  margin-bottom: 10px;
}

.tags-share-area ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  font-weight: 700;
  font-size: 12px;
  background: #d967fd;
  color: #fff;
  padding: 5px 15px;
  border: 1px solid #d967fd;
  text-transform: uppercase;
  transition: all 0.3s;
}

.tags-share-area ul li a:hover {
  background: transparent;
  color: #d967fd;
  border: 1px solid #d967fd;
}

.tags-share-area ul li.fb-hover a {
  background: #3b579d;
  border: 1px solid #3b579d;
  color: #fff;
}

.tags-share-area ul li.tw-hover a {
  background: #1da1f2;
  border: 1px solid #1da1f2;
  color: #fff;
}

.tags-share-area ul li.in-hover a {
  background: #007bb6;
  border: 1px solid #007bb6;
  color: #fff;
}

.tags-share-area ul li.insta-hover a {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  border: 1px solid;
  color: #fff;
}

.tags-share-area ul li a i {
  font-weight: 500;
  transform: scale(1.4);
}

.customer-comment {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px dashed #000;
  margin-bottom: 30px;
}

.customer-comment:last-child {
  margin-bottom: 0;
  border: 0;
}

.customer-comment-img {
  width: 50px;
  margin-right: 15px;
}

.customer-comment-img img {
  width: 100%;
  border-radius: 50%;
}

.customer-comment-text {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding-bottom: 40px;
}

.customer-comment-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #000;
}

.comment-name {
  font-weight: 700;
}

.comment-reply-btn {
  cursor: pointer;
  transition: all 0.3s;
}

.comment-reply-btn:hover {
  color: #d967fd;
}

.comment-reply-btn i {
  margin-right: 5px;
}

.comment-reply-btn span {
  font-weight: 700;
}

.comment-p {
  margin-top: 5px;
}

.comment-reply-area > .row {
  padding: 20px 5px 0 5px;
}

.recent-popular-tabs.nav-tabs .nav-link {
  padding: 0;
  color: #000;
  border: 0;
  margin-right: 15px;
}

.recent-popular-tabs.nav-tabs .nav-link.active {
  color: var(--secondary-color);
}

.customer-comment .customer-comment {
  padding-left: 32px;
  border-bottom: 0;
  margin-bottom: 0;
}

.comment-reply-area {
  display: none;
}

.show-reply-comment {
  display: block;
}

.single-blog-img {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.single-blog-img:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.3s;
  z-index: 1;
}

.single-blog-img img {
  width: 100%;
  transition: all 0.3s;
}

.single-blog:hover .single-blog-img img {
  transform: scale(1.15);
}

.blog-img-hover-text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  z-index: 1;
  display: none;
}

.single-blog:hover .single-blog-img:before {
  opacity: 1;
}

.blogs-sec .single-blog {
  margin: 17px 0 0 0;
}

.blogs-sec .single-blog.mt-0-mob {
  margin-top: 0;
}

.blog-img-hover-text h3 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0 15px;
  line-height: 1.15;
  color: #fff;
  clear: both;
}

.blog-detail-area {
  width: 100%;
  padding: 20px 15px 0 15px;
  background: #fff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08);
}

.blog-detail-area h6 {
  background: #d967fd;
  float: left;
  padding: 5px 15px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.blog-detail-area a {
  text-decoration: none;
}

.blog-detail-area h3 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 5px 0;
  line-height: 1.15;
  color: #000;
  clear: both;
  transition: all 0.3s;
}

.blog-detail-area h3:hover {
  color: #d967fd;
}

.blog-detail-area p {
  line-height: 28px;
  margin: 0;
}

.blog-detail-area p > a {
  color: #d967fd;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
}

.blog-date-name {
  padding: 15px 0 10px 0;
  overflow: hidden;
}

.blog-date-name p {
  font-size: 10px;
  color: #888888;
  float: left;
}

.blog-date-name p + p {
  float: right;
  padding-top: 3px;
}

.blog-date-name p span {
  text-transform: capitalize;
}

.blog-date-name img {
  width: auto;
  height: 24px;
  border-radius: 50%;
  margin-top: -4px;
  margin-right: 3px;
}

.blog-like-share {
  position: relative;
  margin: 0 -15px;
  padding: 5px 0;
  display: flex;
  border-top: 1px solid #ddd;
}

.blog-like-share i {
  margin-right: 5px;
}

.blog-like-share .col-6 a {
  color: #888888;
  text-decoration: none;
  font-size: 13px;
  transition: all 0.3s;
}

.blog-like-share .col-6 a I {
  font-size: 14px;
}

.blog-like-share .col-6 a:hover {
  color: #d967fd;
}

.blog-like-share .col-6 a + a {
  margin-left: 10px;
}

.blog-like-share .col-6 + .col-6 {
  text-align: right;
}

.blog-right-bar {
  width: 100%;
  background: #fff;
  padding: 40px 30px;
}

.blog-right-detail h3 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 15px 0;
  line-height: 1.15;
  color: #000;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.blog-right-detail .contact-form-input {
  margin-bottom: 0;
}

.blog-right-detail .contact-form-input ~ i {
  left: 28px;
  transform: translateY(-50%) scale(0.9);
}

.blog-right-detail + .blog-right-detail {
  margin-top: 35px;
}

.blog-cat {
  list-style: none;
  margin: 0;
  padding: 0;
}

.blog-cat li {
  width: 100%;
}

.blog-cat li a {
  width: 100%;
  padding: 5px 0;
  display: inline-block;
  color: #000;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}

.blog-cat li:first-child a {
  padding-top: 0;
}

.blog-cat li:last-child a {
  padding-bottom: 0;
}

.blog-cat li a:hover {
  color: #d967fd;
}

.blog-recent {
  list-style: none;
  margin: 0;
  padding: 0;
}

.blog-recent li {
  width: 100%;
}

.blog-recent li a {
  width: 100%;
  padding: 5px 0;
  display: inline-block;
  color: #000;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}

.blog-recent li:first-child a {
  padding-top: 0;
}

.blog-recent li:last-child a {
  padding-bottom: 0;
}

.blog-recent li a:hover {
  color: #d967fd;
}

.blog-recent-img {
  width: 100px;
  padding-right: 15px;
  float: left;
}

.blog-recent-img img {
  width: 100%;
}

.blog-recent-detail {
  width: 100%;
  max-width: calc(100% - 100px);
  float: left;
}

.blog-recent-detail p {
  font-size: 10px;
  color: #888888;
  margin-bottom: 2px;
  transition: all 0.3s;
}

.blog-recent-detail h3 {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
  transition: all 0.3s;
}

.blog-recent-detail:hover p,
.blog-recent-detail:hover h3 {
  color: #d967fd;
}

.work-process-img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

.videowork-process-img {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.videowork-process-img img {
  width: 100%;
}

.work-process-img-col {
  margin-top: -60px;
  margin-bottom: -15px;
  overflow: hidden;
}

.smm-process-img {
  width: 220%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.smm-process-steps-left,
.smm-process-steps-right {
  clear: both;
}

.smm-process-steps-left,
.smm-process-steps-right {
  margin: 0 0 25px;
}

.smm-process-steps-left h6,
.smm-process-steps-right h6 {
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  font-weight: 600;
  margin: 0 0 10px;
  color: #000;
}

.smm-process-steps-left h6 span,
.smm-process-steps-right h6 span {
  color: #d967fd;
}

.smm-process-steps-left h6 span {
  display: none;
}

.smm-process-steps-left h6 span.mob-num {
  display: initial;
}

.smm-process-steps-left p,
.smm-process-steps-right p {
  margin: 0;
}

.reverse-mob {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.order-mb-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-mb-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-mb-3 {
  -ms-flex-order: 3;
  order: 3;
}

.contact-video-container {
  width: 100%;
  position: relative;
  margin-bottom: 55px;
}

.contact-video-container svg {
  position: relative;
  left: 50%;
  width: 75% !important;
  transform: translate3d(0px, 0px, 0px) translateX(-50%) !important;
}

.work-process-img:before,
.work-process-img:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.work-process-img:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.work-process-img img {
  width: 100%;
}

.work-video-popup {
  font-size: 32px;
  width: 75px;
  height: 75px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 6px 30px 0 rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.work-video-popup img {
  position: absolute;
  top: 50%;
  left: 53%;
  height: 32px;
  width: auto;
  transform: translate(-50%, -50%);
}

.work-video-popup i {
  position: absolute;
  top: 50%;
  left: 52%;
  font-size: 32px;
  transform: translate(-50%, -50%);
}

.work-video-popup > .after,
.work-video-popup > .before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}

.work-video-popup > .after {
  opacity: 0.2;
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.5s infinite;
}

.work-video-popup > .before {
  transform: scale(1);
  opacity: 0.1;
  -webkit-animation: 1.5s pulse 0.3s infinite;
  animation: 1.5s pulse 0.3s infinite;
}

.banner-video-popup {
  display: none;
}

.banner-link-video-popup {
  position: relative;
  top: -4px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 6px 30px 0 rgba(255, 255, 255, 0.5);
  margin-right: 10px;
  float: left;
}

.inner-banner .link-area a.simple-a .banner-link-video-popup img {
  position: absolute;
  top: 50%;
  left: 55%;
  height: 15px;
  width: auto;
  transform: translate(-50%, -50%);
  margin-right: 0;
}

.banner-link-video-popup > .after,
.banner-link-video-popup > .before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}

.banner-link-video-popup > .after {
  opacity: 0.2;
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.5s infinite;
}

.banner-link-video-popup > .before {
  transform: scale(1);
  opacity: 0.1;
  -webkit-animation: 1.5s pulse 0.3s infinite;
  animation: 1.5s pulse 0.3s infinite;
}

.single-workflow {
  text-align: center;
  margin-top: 15px;
  position: relative;
}

.single-workflow img {
  width: 100%;
  max-width: 80px;
}

.single-workflow h4 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 18px;
  color: #000;
}

.single-workflow h6 {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  padding: 5px 0 3px;
}

.single-workflow p {
  width: 100%;
  max-width: 278px;
  margin: 0 auto;
  margin-top: 7px;
  line-height: 1.15;
}

.complete-about-service .col-md-4:nth-child(odd) {
  /* background: #eee; */
}

.complete-about-service .col-md-4.about-service-bg {
  background: url("../src/assets/images/about-service-img.jpg") no-repeat center
    center;
  background-size: cover;
}

.single-about-service {
  text-align: center;
  position: relative;
  padding: 35px 1.2rem;
}

.single-about-service img {
  max-height: 80px;
}

.single-about-service h4 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 18px;
  color: #000;
}

.single-about-service p {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  margin-top: 7px;
  line-height: 1.15;
}

.inner-about-sec .single-about-service p {
  max-width: 350px;
}

.contact-form-input {
  width: 100%;
  padding: 10px 15px 10px 40px;
  font-weight: 600;
  outline: 0;
  border: 1px solid #d7d7d7;
  color: #4b4b4b;
  margin-bottom: 14px;
  border-radius: 0;
}

.contact-form-input:focus {
  border: 1px solid #d967fd;
}

.contact-form-input ~ i {
  position: absolute;
  left: 22px;
  top: 50%;
  font-weight: 900;
  transform: translateY(calc(-50% - 8px)) scale(0.9);
  color: #d967fd;
  transition: all 0.3s;
}

.contact-form-input::placeholder {
  color: #acabab;
}

.contact-form-textarea {
  width: 100%;
  padding: 10px 15px;
  font-weight: 600;
  outline: 0;
  border: 1px solid #d7d7d7;
  color: #4b4b4b;
  height: 125px;
  resize: none;
  border-radius: 0;
}

.contact-form-textarea:focus {
  border: 1px solid #d967fd;
}

.contact-form-textarea::placeholder {
  color: #acabab;
}

.contact-form-submit {
  color: #fff;
  position: relative;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 10px 30px;
  z-index: 2;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
}

.contact-form-submit-area {
  display: inline-block;
  position: relative;
  margin-top: 30px;
}

.contact-form-submit-area:before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #000;
  z-index: 2;
  transition: all 0.3s;
}

.contact-form-submit-area:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.contact-form-submit-area:hover:before {
  left: 0;
  top: 0;
  border: 2px solid #d967fd;
}

.contact-form-submit-area:hover:after {
  left: 0;
  top: 0;
}

.contact-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-ul li {
  position: relative;
  padding-bottom: 5px;
  padding-left: 25px;
  font-weight: 300;
}

.contact-page .contact-ul li {
  text-align: left;
}

.contact-ul li i {
  position: absolute;
  left: 0;
  top: 40%;
  font-size: 15px;
  transform: translateY(-50%);
  color: #d967fd;
}

.contact-ul li i.fa-phone {
  transform: translateY(-50%) scaleX(-1);
}

.vertical-workprocess-row {
  width: 100%;
  position: relative;
}

.work-left-img {
  width: 100%;
  text-align: center;
  position: relative;
}

.work-left-img img {
  width: 100%;
  max-width: 300px;
}

.work-detail-left {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-left: 30px;
  transform: translateY(-50%);
}

.portfolio-search {
  position: relative;
  display: inline-block;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 35px;
  z-index: 3;
}

.portfolio-search-input {
  background: #fff;
  width: 100%;
  padding: 15px 30px 15px 40px;
  float: left;
  font-weight: 800;
  outline: 0;
  border: 2px dashed #757575;
  color: #4b4b4b;
  border-radius: 0;
  margin-right: 0px;
  /*margin-top: 15px;*/
}

.portfolio-search-input ~ i {
  position: absolute;
  left: 18px;
  top: 21px;
  font-weight: 900;
  transform: scale(0.9);
  color: #d967fd;
  transition: all 0.3s;
}

.portfolio-search-submit {
  padding: 17px 40px;
  border: 0;
  color: #fff;
  font-weight: 800;
  background: #d967fd;
  outline: 0;
  text-transform: uppercase;
  float: left;
  width: 100%;
  margin-top: 10px;
  border-radius: 0;
}

.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
}

.autocomplete .autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete .autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete .autocomplete-items div:hover,
.autocomplete-items div.autocomplete-active {
  background-color: #e9e9e9;
}

.portfolio-tabs ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.portfolio-scroll-tabs {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 45px;
}

.portfolio-tabs.portfolio-scroll-tabs
  .nav-tabs
  .nav-item:first-child
  .nav-link {
  margin-left: 0;
}

.portfolio-tabs.portfolio-scroll-tabs .nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0;
}

.prev-portfolio,
.next-portfolio {
  position: absolute;
  top: 0;
  background: #d967fd;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 0 20px 25px rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
}

.prev-portfolio {
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.next-portfolio {
  right: 0;
  visibility: hidden;
  opacity: 0;
}

.portfolio-scroll-tabs ul {
  padding-bottom: 15px;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

ul.scrollable-tabs {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.scrollable-tabs ~ .next-portfolio {
  visibility: visible;
  opacity: 1;
}

.portfolio-tabs .nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
  padding: 10px 15px;
  color: var(--secondary-color);
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s;
  margin: 0 7px;
}

.portfolio-tabs .nav-tabs .nav-link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 4px);
  height: 5px;
  background: #d7d7d7;
  transform: translateX(-50%);
  transition: all 0.3s;
}

.portfolio-tabs .nav-tabs .nav-link.active {
  background: var(--secondary-color);
  color: #fff;
}

.portfolio-tabs .nav-tabs .nav-link.active:before {
  background: var(--secondary-color);
}

.portfolio-content {
  padding: 48px 0 6px 0;
  width: 100%;
}

.portfolio-content .tab-content {
  overflow: hidden;
}

.portfolio-content .tab-pane .row {
  margin: 0 -7px;
}

.portfolio-content .tab-pane .row .col-md-3 {
  padding: 7px 7px;
}

.portfolio-content img {
  width: 100%;
}

.portfolio-content img.lazy {
  /* background: url("../images/portfolio-loading.gif") no-repeat center center; */
  background-size: 50px;
}

.portfolio-img-hover {
  position: relative;
  width: 100%;
}

.portfolio-img-hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: radial-gradient(transparent, rgba(0, 0, 0, 0.8));
  transition: all 0.3s;
}

.portfolio-img-hover:after {
  content: "\e910";
  position: absolute;
  right: 15px;
  bottom: 5px;
  color: #fff;
  font-family: "icomoon2";
  font-size: 26px;
  opacity: 0;
  transition: all 0.3s;
}

.portfolio-img-hover-container {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
  display: none;
}

.portfolio-img-hover-container h2 {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.portfolio-industry,
.portfolio-img-hover-container h6 {
  background: #d967fd;
  padding: 2px 15px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
}

.portfolio-industry {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 1;
  transition: all 0.3s;
}

.portfolio-img-hover-container p {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin: 0 0 10px 0;
  padding: 5px 0;
  line-height: 1.15;
  color: #fff;
  clear: both;
}

.portfolio-img-hover-container i {
  color: #fff;
  font-size: 26px;
  display: none;
}

.portfolio-img-hover:hover:before,
.portfolio-img-hover:hover:after,
.portfolio-img-hover:hover .portfolio-img-hover-container {
  opacity: 1;
}

.inner-service-tabs ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.inner-service-tabs .nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
  padding: 10px 15px;
  color: #d967fd;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s;
  margin: 0 7px;
}

.inner-service-tabs .nav-tabs .nav-link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 4px);
  height: 5px;
  background: #d7d7d7;
  transform: translateX(-50%);
  transition: all 0.3s;
}

.inner-service-tabs .nav-tabs .nav-link.active {
  background: #d967fd;
  color: #fff;
}

.inner-service-tabs .nav-tabs .nav-link.active:before {
  background: #d967fd;
}

.inner-service-tabs .nav-tabs .nav-item {
  margin-top: 10px;
}

.inner-service-content {
  padding: 55px 0 0 0;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.inner-service-content img {
  position: relative;
  top: 0;
  width: 100%;
  max-width: 300px;
  transform: translateY(0);
}

.inner-service-content.web-page-different img {
  max-width: 375px;
}

.inner-service-content.video-page-different img {
  max-width: 370px;
}

.inner-service-content.mobile-app-page-different img {
  max-width: 370px;
}

.inner-service-content.mobile-app-page-different
  .tab-pane
  > .row
  > .col-md-6
  + .col-md-6
  .section-heading {
  position: relative;
  top: 50%;
  transform: translateY(calc(-50% - 20px));
}

.inner-service-content.dm-page-different img {
  max-width: 400px;
}

.inner-service-content .section-heading.left-heading {
  padding-right: 30px;
}

.inner-service-content .section-heading.left-heading {
  padding-right: 0;
  margin-top: 50px;
}

.inner-portfolio {
  padding-bottom: 0;
}

.inner-portfolio .tab-pane .row .col-md-3 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.inner-banner-input {
  background: rgba(0, 0, 0, 0.6);
  width: calc(100% - 205px);
  padding: 13px 15px 13px 45px;
  float: left;
  font-weight: 700;
  outline: 0;
  border: 0;
  color: #ffffff;
  border-radius: 0;
  border: 2px dashed #ffffff;
  margin-right: 0;
  width: 100%;
  margin-bottom: 10px;
}

.inner-banner-input::placeholder {
  color: #989898;
}

.inner-banner-heading form {
  position: relative;
}

.inner-banner-heading form::before {
  content: "";
  position: absolute;
  height: 17px;
  width: 18px;
  /* background: url(../images/images-pencil.png) no-repeat center center; */
  background-size: contain;
  top: 27px;
  left: 12px;
  transform: translateY(-50%);
}

.dm-banner .inner-banner-heading .inner-banner-input {
  padding-left: 40px;
}

.dm-banner .inner-banner-heading form::before {
  content: "\f0ac";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  font-weight: 600;
  font-size: 18px;
  width: auto;
  height: auto;
  top: 27px;
}

.inner-banner-submit {
  position: relative;
  padding: 15px 36px;
  border: 0;
  color: #fff;
  font-weight: 800;
  background: #d967fd;
  outline: 0;
  border-radius: 0;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  margin-left: 0;
}

.inner-banner-submit:focus {
  outline: 0;
}

button.inner-banner-submit:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 20px;
  position: absolute;
  left: -100%;
  transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: -1;
}

button.inner-banner-submit:hover:before {
  left: 100%;
}

button.inner-banner-submit span {
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--secondary-color) 50%,
    #fff 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 0.6s ease;
}

button.inner-banner-submit:hover span {
  background-position: 0 100%;
}

.green-sec-input {
  background: transparent;
  width: 100%;
  padding: 15px 30px 15px 42px;
  float: left;
  font-weight: 800;
  outline: 0;
  border: 2px dashed #fff;
  color: #fff;
  border-radius: 0;
  margin-bottom: 10px;
}

.green-sec-input::placeholder {
  color: #efefef;
}

.green-sec-submit {
  background: #fff;
  color: #d967fd;
  padding: 15px 48px;
  border: 0;
  font-weight: 800;
  border: 2px solid #fff;
  outline: 0;
  margin-left: 0;
  transition: all 0.3s;
  border-radius: 0;
  width: 100%;
}

.green-sec-submit:hover {
  background: transparent;
  color: #fff;
}

.lb-service,
.dm-service {
  position: relative;
  width: 100%;
  /* background: #808080; */
  padding: 45px 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column-reverse;
}

.dm-service {
  padding: 30px 0 0 0;
}

.lb-service-1,
.web-service-1,
.mobile-service-1,
.video-service-1,
.video-service-3,
.video-service-5,
.dm-service-1,
.dm-service-3,
.smm-service-1,
.smm-service-3,
.fb-service-1,
.fb-service-3,
.fb-service-5,
.instagram-service-1,
.instagram-service-3,
.twitter-service-1,
.twitter-service-3,
.linkedin-service-1,
.linkedin-service-3 {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 300px;
  margin-top: 35px;
  overflow: hidden;
}

.lb-service-2,
.web-service-2,
.mobile-service-2,
.video-service-2,
.video-service-4,
.video-service-6,
.dm-service-2,
.smm-service-2,
.smm-service-4,
.fb-service-2,
.fb-service-4,
.instagram-service-2,
.instagram-service-4,
.twitter-service-2,
.twitter-service-4,
.linkedin-service-2,
.linkedin-service-4 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  margin-top: 35px;
  overflow: hidden;
}

.dm-service-1,
.dm-service-2,
.dm-service-3,
.smm-service-1,
.smm-service-2,
.smm-service-3,
.smm-service-4,
.fb-service-1,
.fb-service-2,
.fb-service-3,
.fb-service-4,
.fb-service-5,
.instagram-service-1,
.instagram-service-2,
.instagram-service-3,
.instagram-service-4,
.twitter-service-1,
.twitter-service-2,
.twitter-service-3,
.twitter-service-4 {
  margin-top: 30px;
}

.further-service-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

/*   
  .lb-service-1 .further-service-bg {
    background: url("../images/logo-branding-lb-service-1.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .lb-service-2 .further-service-bg {
    background: url("../images/logo-branding-lb-service-2.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .web-service-1 .further-service-bg {
    background: url("../images/website-web-service-1.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .web-service-2 .further-service-bg {
    background: url("../images/website-web-service-2.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .mobile-service-1 .further-service-bg {
    background: url("../images/mobileapp-mobile-service-1.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .mobile-service-2 .further-service-bg {
    background: url("../images/mobileapp-mobile-service-2.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .video-service-1 .further-service-bg {
    background: url("../images/video-video-service-1.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .video-service-2 .further-service-bg {
    background: url("../images/video-video-service-2.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .video-service-3 .further-service-bg {
    background: url("../images/video-video-service-3.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .video-service-4 .further-service-bg {
    background: url("../images/video-video-service-4.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .video-service-5 .further-service-bg {
    background: url("../images/video-video-service-5.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .video-service-6 .further-service-bg {
    background: url("../images/video-video-service-6.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .dm-service-1 .further-service-bg {
    background: url("../images/dm-dm-service-1.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .dm-service-2 .further-service-bg {
    background: url("../images/dm-dm-service-2.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .dm-service-3 .further-service-bg {
    background: url("../images/dm-dm-service-3.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .smm-service-1 .further-service-bg {
    background: url("../images/service-detail-facebook1.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .smm-service-2 .further-service-bg {
    background: url("../images/service-detail-instagram.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .smm-service-3 .further-service-bg {
    background: url("../images/service-detail-twitter2.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .smm-service-4 .further-service-bg {
    background: url("../images/service-detail-linkedin.jpg")
      no-repeat center center;
    background-size: cover;
  }
  
  .fb-service-1 .further-service-bg {
    background: url("../images/facebook-Audience_Targeting.jpg")
      no-repeat center center;
    background-size: cover;
  } */

.fb-service-2 .further-service-bg {
  /* background: url("../images/service-detail-2.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.fb-service-3 .further-service-bg {
  /* background: url("../images/service-detail-31.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.fb-service-4 .further-service-bg {
  /* background: url("../images/service-detail-4.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.fb-service-5 .further-service-bg {
  /* background: url("../images/service-detail-5.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.instagram-service-1 .further-service-bg {
  /* background: url("../images/instagram-instagram_marketing.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.instagram-service-2 .further-service-bg {
  /* background: url("../images/instagram-market_segmentaion.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.instagram-service-3 .further-service-bg {
  /* background: url("../images/instagram-marketing_plan.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.instagram-service-4 .further-service-bg {
  /* background: url("../images/instagram-scaling_campaign.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.twitter-service-1 .further-service-bg {
  /* background: url("../images/twitter-target_audience.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.twitter-service-2 .further-service-bg {
  /* background: url("../images/twitter-twitter-landing-page-development.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.twitter-service-3 .further-service-bg {
  /* background: url("../images/twitter-3.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.twitter-service-4 .further-service-bg {
  /* background: url("../images/twitter-4.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.linkedin-service-1 .further-service-bg {
  /* background: url("../images/linkedin-audits.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.linkedin-service-2 .further-service-bg {
  /* background: url("../images/linkedin-ad_strategy.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.linkedin-service-3 .further-service-bg {
  /* background: url("../images/linkedin-ad-management.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.linkedin-service-4 .further-service-bg {
  /* background: url("../images/service-detail-5.jpg") */
  /* no-repeat center center; */
  background-size: cover;
}

.about-service-1 {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 150px;
  /* background: url("../images/about-about-1.jpg") */
  /* no-repeat center center; */
  background-size: cover;
  margin-top: 30px;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.lb-service .col-lg-6 .section-heading {
  padding-right: 0;
  padding-left: 0;
}

.lb-service .col-lg-6.offset-lg-6 .section-heading {
  padding-left: 0;
  padding-right: 0;
}

.lb-service .section-heading p.simple-p {
  margin-bottom: 0;
  padding-right: 0;
}

.dm-service .col-lg-6 .section-heading {
  padding-right: 0;
  padding-left: 0;
}

.dm-service .col-lg-6.offset-lg-6 .section-heading {
  padding-left: 0;
  padding-right: 0;
}

.dm-service .section-heading p.simple-p {
  margin-bottom: 0;
  padding-right: 0;
}

.lb-service-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.lb-service-link-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.3s;
  z-index: 1;
}

.lb-service-link:hover ~ .lb-service-link-hover {
  opacity: 0.6;
}

.lb-service-link:hover ~ .further-service-bg {
  transform: scale(1.1);
}

.lb-service .link-area a.hover-effect,
.dm-service .link-area a.hover-effect {
  text-align: center;
  white-space: nowrap;
}

.lb-service .section-heading .link-area,
.dm-service .section-heading .link-area {
  margin-top: 30px;
}

.inner-banner-form {
  display: none;
}

.get-free-quote-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 199;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}

.get-free-quote-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 30px);
  background: #fff;
  padding: 20px 25px 40px 25px;
  z-index: 200;
  transition: all 0.3s;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
}

.inner-banner-form:before,
.get-free-quote-container:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 1px solid #fff;
  z-index: -1;
  transition: all 0.3s;
}

.inner-banner-form:focus-within:before,
.get-free-quote-container:focus-within:before {
  top: -1px;
  left: -1px;
  border-color: #d967fd;
}

.inner-banner-form-heading h1,
.inner-banner-form-heading h2 {
  font-weight: 700;
  font-size: 30px;
  color: #000;
  padding-top: 3px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding-bottom: 15px;
}

.inner-banner-form-heading h1 span,
.inner-banner-form-heading h2 span {
  font-family: "Nunito Sans", sans-serif;
}

.inner-banner-form-input {
  width: 100%;
  padding: 10px 15px 10px 40px;
  font-weight: 600;
  outline: 0;
  border: 1px solid #d7d7d7;
  color: #4b4b4b;
  margin-bottom: 14px;
  border-radius: 0;
}

.inner-banner-form-input ~ i {
  position: absolute;
  left: 25px;
  top: 50%;
  font-weight: 900;
  transform: translateY(calc(-50% - 8px)) scale(0.9);
  color: #d967fd;
  transition: all 0.3s;
}

.inner-banner-form-input::placeholder {
  color: #acabab;
}

.inner-banner-form-textarea {
  width: 100%;
  padding: 10px 15px;
  font-weight: 600;
  outline: 0;
  border: 1px solid #d7d7d7;
  color: #4b4b4b;
  height: 125px;
  resize: none;
  border-radius: 0;
}

.inner-banner-form-textarea::placeholder {
  color: #acabab;
}

.inner-banner-form-input:focus,
.inner-banner-form-textarea:focus {
  border: 1px solid #d967fd;
}

.inner-banner-form-submit {
  color: #fff;
  position: relative;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 10px 30px;
  z-index: 2;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
}

.inner-banner-form-submit-area {
  display: inline-block;
  position: relative;
  margin-top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.inner-banner-form-submit-area:before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #000;
  z-index: 2;
  transition: all 0.3s;
}

.inner-banner-form-submit-area:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.inner-banner-form-submit-area:hover:before {
  left: 0;
  top: 0;
  border: 2px solid #d967fd;
}

.inner-banner-form-submit-area:hover:after {
  left: 0;
  top: 0;
}

.single-campaign {
  position: relative;
  margin: 0 0 15px 0;
  border: 1px solid transparent;
  padding: 25px 15px 30px 15px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  cursor: default;
  background: #fff;
  transition: all 0.3s;
}

.single-campaign:before {
  content: "";
  position: absolute;
  bottom: -1px;
  right: 10px;
  height: 4px;
  width: 115px;
  background: #d967fd;
}

.single-campaign:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: 1px solid #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.single-campaign:hover:after {
  top: -2px;
  left: -2px;
}

.campaign-img {
  width: 70px;
  height: 100%;
  padding-right: 10px;
  float: left;
}

.campaign-img.diff-campaign {
  padding-right: 5px;
}

.campaign-img img {
  width: 100%;
}

.campaign-right-detail {
  float: left;
  width: calc(100% - 70px);
  padding-left: 10px;
}

.campaign-right-detail h3 {
  font-size: 21px;
  font-weight: 700;
  color: #000;
  font-family: "Poppins", sans-serif;
}

.campaign-right-detail p {
  line-height: 1.15;
  margin-bottom: 0;
  font-size: 17px;
}

.campaigns-number {
  position: absolute;
  bottom: -3px;
  right: 13px;
  font-size: 90px;
  font-weight: 600;
  line-height: 1;
  color: #d967fd;
  opacity: 0.1;
  font-family: "Poppins", sans-serif;
}

.vertical-process {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  position: relative;
}

.vertical-process .section-heading {
  position: relative;
  top: auto;
  transform: none;
  padding: 55px 0;
}

.vertical-process:last-child .section-heading {
  padding-bottom: 0;
}

.vertical-process .section-heading p.simple-p {
  margin-bottom: 0;
  padding-right: 0;
}

.vertical-process:nth-child(odd) {
  text-align: right;
}

.vertical-process:nth-child(even) {
  text-align: left;
}

.vertical-process:nth-child(odd) .row {
  flex-direction: column-reverse;
}

.vertical-process:nth-child(odd),
.vertical-process:nth-child(even) {
  text-align: center;
}

.vertical-process img {
  width: 100%;
  max-width: 300px;
}

.about-process {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.about-process:nth-child(odd) .row {
  flex-direction: column-reverse;
}

.about-process .section-heading {
  position: relative;
  top: auto;
  transform: none;
  padding: 45px 0;
}

.about-process:last-child .section-heading {
  padding-bottom: 0;
}

.about-process .section-heading p.simple-p {
  margin-bottom: 0;
  padding-right: 0;
}

.about-process img {
  width: 100%;
  max-width: 200px;
}

.about-process .section-heading .link-area {
  margin-top: 10px;
}

.dm-ppc {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.dm-ppc .section-heading {
  position: relative;
  top: auto;
  transform: none;
  padding: 30px 0 0 0;
}

.dm-ppc .section-heading p.simple-p {
  margin-bottom: 0;
  padding-right: 0;
}

.dm-ppc img {
  width: 100%;
  transform: none;
}

.dm-ppc .section-heading .link-area {
  margin-top: 10px;
}

.smm-green-sec img {
  margin-top: 0px;
  width: 90%;
  margin-bottom: 0;
}

.about-float-number {
  display: none;
}

.awards-banner {
  display: flex;
  justify-content: space-between;
}

.awards-banner-img {
  float: left;
  text-align: center;
}

.awards-banner-img img {
  height: 90px;
}

.faq-sec .card {
  background: transparent;
  border: 0;
}

.faq-sec .card .card-header {
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  position: relative;
  line-height: 1.6;
  margin-top: 0;
  padding: 0;
}

.faq-sec .card .card-header a {
  color: #4b4b4b;
  width: 100%;
  display: inline-block;
  padding: 15px 20px;
  padding-right: 40px;
}

.faq-sec .card .card-header a > span {
  color: #d967fd;
  padding-right: 9px;
}

.faq-sec .card .card-header a:before {
  content: "-";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #4b4b4b;
  right: 10px;
  top: 50%;
  font-size: 30px;
  line-height: 20px;
  transform: translateY(-50%);
}

.faq-sec .card .card-header a.collapsed:before {
  content: "+";
  font-size: 22px;
  line-height: 25px;
}

.faq-sec .card .card-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.7rem 1.25rem;
}

.small-accordion {
  text-align: left;
  margin-top: 45px;
}

.small-accordion .card .card-header {
  font-size: 16px;
}

.small-accordion .card .card-header a {
  padding: 15px 50px 15px 20px;
}

.small-accordion .card .card-header a:before {
  content: "\f107";
  font-size: 22px;
  line-height: 35px;
  border: 0;
}

.small-accordion .card .card-header a.collapsed:before {
  content: "\f106";
  font-size: 22px;
  line-height: 35px;
}

.small-accordion .card:last-child .card-header {
  border-bottom: 0;
}

.faq-sec .card:last-child .card-body {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 0;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.contact-faq-sec .card {
  background: transparent;
  border: 0;
}

.contact-faq-heading {
  background: #d967fd;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  color: #000;
  position: relative;
  line-height: 30px;
  margin-top: 10px;
  padding: 12px 40px;
  color: #fff;
  text-transform: uppercase;
}

.contact-page #contact_form {
  margin-bottom: 45px;
}

.contact-faq-sec .card .card-header {
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 500;
  color: #000;
  position: relative;
  line-height: 30px;
  margin-top: 0;
  padding: 0;
  border: 0;
}

.contact-faq-sec .card .card-header a {
  color: #4b4b4b;
  width: 100%;
  display: inline-block;
  padding: 12px 40px;
  background: #eff2f7;
}

.contact-faq-sec .card .card-header a:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #4b4b4b;
  right: 40px;
  top: 50%;
  font-size: 12px;
  line-height: 30px;
  transform: translateY(-50%);
}

.contact-faq-sec .card .card-header a.collapsed:before {
  content: "\f067";
}

.about-second-img {
  width: 100%;
  max-width: 475px;
}

.about-second-sec-img {
  position: relative;
  display: inline-block;
  margin-top: 45px;
}

.about-second-sec-img .work-video-popup {
  border: 6px solid rgba(0, 0, 0, 0.6);
  background: transparent;
  z-index: 1;
}

.step-form {
  width: 100%;
  height: 100vh;
  position: relative;
}

.step-footer {
  width: 100%;
  background: #fff;
  border-top: 1px solid #ddd;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.step-footer-loader {
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  background: #d967fd;
  transition: all 0.5s;
  display: none;
}

.step-footer-loader:after {
  content: "";
  position: absolute;
  right: 0;
  top: 49%;
  background: #d967fd;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  width: 10px;
  height: 10px;
  transform: translate(50%, -50%);
}

.step-form-submit {
  color: #fff;
  position: relative;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 10px 45px;
  z-index: 2;
  font-weight: 700;
  text-transform: uppercase;
}

.step-form-submit-area {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.step-form-submit-area:before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #000;
  z-index: 2;
  transition: all 0.3s;
}

.step-form-submit-area:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.step-form-submit-area:hover:before {
  left: 0;
  top: 0;
  border: 2px solid #d967fd;
}

.step-form-submit-area:hover:after {
  left: 0;
  top: 0;
}

.step-container {
  width: 100%;
  position: relative;
  top: 61px;
  height: calc(100% - 145px);
  overflow: auto;
}

.step-container.last-step-page {
  height: calc(100% - 70px);
}

.step-center {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.step-center.top-48-desktop {
  top: 48%;
}

.step-selective {
  width: 100%;
  display: none;
}

.step-label {
  width: calc(100% + 10px);
  height: calc(100% - 20px);
  background: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  position: relative;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  margin: 0 -5px;
  margin-bottom: 20px;
  transition: all 0.3s;
}

.step-label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 1px solid #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.step-label:hover:after {
  top: -1px;
  left: -1px;
}

:checked + label.step-label {
  box-shadow: 1px 1px 8px rgba(25, 164, 125, 0.6);
}

:checked + label.step-label:after {
  top: -1px;
  left: -1px;
}

.step-label img {
  width: 100%;
  text-align: center;
}

.step-heading {
  margin: 0;
  font-weight: 700;
  text-align: center;
  font-size: 13px;
  padding: 10px 10px;
  color: #010101;
  background: #fff;
  position: relative;
}

.small-tick-area .step-heading {
  margin-top: -34px;
}

.small-tick-area .step-img-holder .absolute-tick {
  height: calc(100% - 34px);
}

.step-pt-20 .step-img-holder {
  padding-top: 20px;
}

.max-900 {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.max-600 {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.max-700 {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.max-500 {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.step-input-row:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 20px;
  width: calc(100% - 30px);
  height: calc(100% - 14px);
  border: 1px solid #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.step-input-row:focus-within:after {
  top: 0px;
  left: 15px;
}

.step-input {
  width: 100%;
  padding: 14px 15px 14px 40px;
  font-weight: 600;
  outline: 0;
  border: 1px solid #d7d7d7;
  color: #4b4b4b;
  margin-bottom: 14px;
  border-radius: 0;
}

.step-other-popup .step-input {
  padding-left: 50px;
}

.step-other-popup .step-input + img {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(calc(-50% - 9px));
  transition: all 0.3s;
  width: 25px;
}

.step-other-popup .step-input-row:after {
  border: 1px solid #fff;
}

.step-input:focus {
  border: 1px solid #d967fd;
}

.step-input ~ i {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(calc(-50% - 7px));
  color: #d967fd;
  transition: all 0.3s;
}

.slogan-step .step-input ~ i {
  transform: translateY(calc(-50% - 8px)) scale(1.4);
}

.step-input ~ i.fa-amilia {
  font-weight: 100;
}

.step-input::placeholder {
  color: #acabab;
}

.step-img-holder {
  position: relative;
}

.step2 .step-img-holder {
  padding-top: 10px;
}

.step2 .step-label img {
  max-width: 65px;
}

.step2 .absolute-tick svg {
  width: 60px;
}

.color-step .step-center {
  padding: 60px 0;
  position: relative;
  top: 0;
  transform: none;
}

.final-step .step-container {
  height: calc(100% - 61px);
}

.final-step .step-label {
  text-align: left;
}

.final-step .step-heading {
  font-size: 18px;
  padding: 10px 15px 0 15px;
  text-align: left;
}

.final-step .step-heading > span {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 64px;
  font-weight: 600;
  line-height: 1;
  color: #d967fd;
  opacity: 0.1;
  font-family: "Poppins", sans-serif;
}

.final-step .step-heading:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 0px;
  width: 4px;
  height: 73px;
  background: #d967fd;
}

.final-step .step-heading + p {
  padding: 10px 15px;
  margin: 0;
  background: #fff;
  line-height: 1.4;
  font-size: 14px;
}

.final-step label .link-area {
  margin: 10px 0 20px;
}

.absolute-tick {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(25, 164, 125, 0);
  transition: all 1s ease-out;
}

:checked + label.step-label .absolute-tick {
  background: rgba(25, 164, 125, 0.7);
}

.absolute-tick svg {
  enable-background: new 0 0 37 37;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-tick .path {
  fill: none;
  stroke: #fff;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.absolute-tick .circ {
  opacity: 0;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.absolute-tick .tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  -webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
  -moz-transition: stroke-dashoffset 1s 0.5s ease-out;
  -ms-transition: stroke-dashoffset 1s 0.5s ease-out;
  -o-transition: stroke-dashoffset 1s 0.5s ease-out;
  transition: stroke-dashoffset 1s 0.5s ease-out;
}

:checked + label.step-label .absolute-tick svg .path {
  opacity: 1;
  stroke-dashoffset: 0;
}

.mfp-close-btn-in .mfp-close {
  opacity: 1;
}

.step-footer-z-index {
  z-index: 2000;
  background: transparent;
  border: none;
}

.footer {
  width: 100%;
  background: #1c1c1c;
  color: #fff;
}

.footer-top {
  padding: 35px 0;
  width: 100%;
}

.home-footer .footer-top {
  text-align: center;
}

.home-footer .footer-logo {
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
}

.home-footer .footer-links {
  font-size: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-footer .footer-links li a {
  text-transform: uppercase;
}

.footer-social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-footer .footer-social-links {
  font-size: 22px;
  margin-bottom: 10px;
}

.home-footer .footer-social-links li {
  text-align: center;
  margin: 0 7px;
  margin-bottom: 15px;
  transition: all 0.3s;
}

.home-footer .footer-logo img {
  max-width: 140px;
}

.home-footer .footer-links li a:hover i.fab {
  color: #fff;
}

.home-footer .footer-social-links li.fb-hover a:hover i.fab {
  color: #3b579d;
}

.home-footer .footer-social-links li.tw-hover a:hover i.fab {
  color: #1da1f2;
}

.home-footer .footer-social-links li.in-hover a:hover i.fab {
  color: #007bb6;
}

.home-footer .footer-social-links li.you-hover a:hover i.fab {
  color: #f00;
}

.home-footer .footer-social-links li.insta-hover a:hover i.fab {
  color: #56407f;
}

.home-footer .footer-social-links li.be-hover a:hover i.fab {
  color: #1769ff;
}

.home-footer .footer-social-links li.dr-hover a:hover i.fab {
  color: #b2215a;
}

.footer-payment-link {
  margin-top: 10px;
}

.footer-payment-link img {
  width: 100%;
  max-width: 360px;
}

.footer-logo img {
  width: 100%;
  max-width: 150px;
}

.footer-logo p {
  line-height: 1.3;
  color: #a7a7a7;
  margin-top: 15px;
}

.footer .contact-ul {
  margin-top: 20px;
}

.footer-links {
  padding: 0;
  margin: 0;
  list-style: none;
  clear: both;
}

.footer-links li {
  float: left;
}

.footer-links li a {
  color: #fff;
  text-decoration: none;
  /* padding: 15px 10px; */
  display: inline-block;
  text-transform: capitalize;
  font-weight: 700;
  transition: all 0.3s;
}

.home-footer .footer-links.footer-social-links li a {
  padding: 0;
}

.footer-heading {
  font-weight: 800;
  margin-bottom: 10px;
}

.inner-footer .footer-heading {
  margin-top: 15px;
}

.inner-footer .footer-links li {
  float: none;
  width: 100%;
}

.inner-footer .footer-links li a {
  font-size: 12px;
  padding: 5px 0;
  color: #a7a7a7;
  font-weight: 800;
}

.inner-footer .footer-links li a:hover {
  color: var(--secondary-color);
}

.inner-footer .contact-ul {
  margin-top: 0;
  font-size: 12px;
}

.inner-footer .contact-ul li {
  padding-left: 20px;
  padding-top: 8px;
  line-height: 1.3;
  font-weight: 800;
  color: #a7a7a7;
}

.inner-footer .footer-social-links li {
  float: left;
  width: auto;
}

.inner-footer .footer-social-links li a {
  padding: 0 10px;
  font-size: 22px;
  margin-top: 25px;
}

.inner-footer .contact-ul li i {
  font-size: 12px;
  top: 17px;
}

.footer-links li a img {
  width: auto;
  max-width: 120px;
  max-height: 45px;
}

.footer-links li a i {
  color: #fff;
  font-weight: 500;
  transition: all 0.3s;
}

.footer-imgs-links {
  display: flex;
  align-items: center;
}

.footer-links li a:hover i.fa-facebook-f {
  color: #3b579d;
}

.footer-links li a:hover i.fa-twitter {
  color: #1da1f2;
}

.footer-links li a:hover i.fa-google-plus-g {
  color: #dd5044;
}

.footer-links li a:hover i.fa-linkedin-in {
  color: #007bb6;
}

.footer-links li a:hover i.fa-youtube {
  color: #f00;
}

.footer-links li a:hover i.fa-instagram {
  color: #fb3958;
}

.footer-links li a:hover i.fa-behance {
  color: #1769ff;
}

.footer-links li a:hover i.fa-dribbble {
  color: #b2215a;
}

.footer-bottom {
  background: #222328;
  padding: 20px 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.footer-bottom-links {
  display: flex;
  float: none;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;
  clear: both;
}

.footer-bottom-links li {
  float: left;
}

.footer-bottom-links li a {
  /* color: #fff; */
  padding: 0 10px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s;
  border-right: 1px solid #fff;
  line-height: 1;
}

.footer-bottom-links li a:hover {
  color: var(--secondary-color);
}

.footer-bottom-links li:last-child a {
  border: 0;
}

.owl-dots {
  display: none;
}

.mob-menu-btn {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}

.mob-menu-btn i {
  position: absolute;
  top: 50%;
  right: 5px;
  font-size: 25px;
  color: #fff;
  transform: translate(0, -50%);
  transition: all 0.3s;
}

.scrolled .mob-menu-btn i,
.white-header .mob-menu-btn i {
  color: #000;
}

.mob-menu {
  position: fixed;
  top: 61px;
  right: 0;
  width: 100%;
  max-height: 0;
  background: #fff;
  overflow: hidden;
  z-index: 105;
  transition: all 0.3s;
}

.display-mob-menu {
  box-shadow: 0 7px 13px rgba(0, 0, 0, 0.2);
  max-height: 400px;
}

.mob-menu ul {
  margin: 0;
  padding: 15px 0;
  list-style: none;
  overflow: auto;
}

.mob-menu ul li {
  font-size: 16px;
}

.mob-menu ul li a {
  padding: 5px 30px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
}

.mob-menu ul li a:hover {
  color: #d967fd;
}

.mob-menu-close {
  padding: 5px 25px;
  color: #000;
  font-weight: 500;
  font-size: 26px;
  text-align: right;
  padding-bottom: 25px;
}

.mob-menu-tel {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.mob-menu-tel a {
  padding: 5px 0;
  text-align: center;
  padding-bottom: 25px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}

.mob-menu-tel a i:before {
  color: #d967fd;
}

.black-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 101;
  display: none;
}

.back-to-top {
  position: fixed;
  bottom: 45px;
  right: 15px;
  width: 40px;
  height: 40px;
  background: #1c1c1c;
  z-index: 100;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transition: all 0.3s;
}

.back-to-top i {
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  transition: all 0.3s;
}

.back-to-top:hover {
  background: #d967fd;
  bottom: 50px;
}

.back-to-top.scrolled-show {
  opacity: 1;
  visibility: visible;
}

.animate-box {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
  z-index: 1;
}

.onload-animate,
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.banner-bottom-left-1 {
  bottom: 0;
  left: 0;
  background: var(--secondary-color);
}

.lb-banner .banner-bottom-left-1 {
  background: #fff;
}

.web-banner .banner-bottom-left-1 {
  background: #fff;
}

.mobile-app-banner .banner-bottom-left-1 {
  background: #fff;
}

.video-banner .banner-bottom-left-1 {
  background: #fff;
}

.banner-bottom-left-2 {
  bottom: 0;
  left: 0;
  transform: translateX(calc(100% + 1px));
  background: #fff;
}

.banner-bottom-left-3 {
  bottom: 0;
  left: 0;
  transform: translateX(calc(100% + 1px)) translateY(calc(-100% - 1px));
  background: var(--secondary-color);
}

.banner-bottom-left-4 {
  bottom: 0;
  left: 0;
  background: #fff;
  transform: translateX(calc(200% + 2px)) translateY(calc(-100% - 1px));
}

.banner-bottom-left-5 {
  bottom: 0;
  left: 0;
  transform: translateY(calc(-200% - 2px));
  background: #fff;
}

.lb-banner .banner-bottom-left-5 {
  background: var(--secondary-color);
}

.web-banner .banner-bottom-left-5 {
  background: var(--secondary-color);
}

.mobile-app-banner .banner-bottom-left-5 {
  background: var(--secondary-color);
}

.video-banner .banner-bottom-left-5 {
  background: var(--secondary-color);
}

.banner-bottom-right-1 {
  bottom: 0;
  right: 0;
  background: var(--secondary-color);
}

.lb-banner .banner-bottom-right-1 {
  background: #fff;
}

.web-banner .banner-bottom-right-1 {
  background: #fff;
}

.mobile-app-banner .banner-bottom-right-1 {
  background: #fff;
}

.video-banner .banner-bottom-right-1 {
  background: #fff;
}

.banner-bottom-right-2 {
  bottom: 0;
  right: 0;
  transform: translateX(calc(-100% - 1px));
  background: #fff;
}

.banner-bottom-right-3 {
  bottom: 0;
  right: 0;
  background: #fff;
  transform: translateX(calc(-200% - 2px)) translateY(calc(-100% - 1px));
}

.portfolio-banner .banner-bottom-right-3 {
  background: var(--secondary-color);
}

.pricing-banner .banner-bottom-right-3 {
  background: var(--secondary-color);
}

.banner-bottom-right-4 {
  bottom: 0;
  right: 0;
  transform: translateX(calc(-100% - 1px)) translateY(calc(-200% - 2px));
  background: var(--secondary-color);
}

.section-top-right-1 {
  top: 0;
  right: 0;
  background: #d7d7d7;
}

.section-top-right-2 {
  top: 0;
  right: 0;
  transform: translateX(calc(-100% - 1px)) translateY(calc(100% + 1px));
  background: rgba(25, 164, 125, 0.6);
}

.section-top-right-3 {
  top: 0;
  right: 0;
  transform: translateX(calc(-100% - 1px));
  background: rgba(25, 164, 125, 0.6);
}

.section-top-right-4 {
  top: 0;
  right: 0;
  transform: translateY(calc(100% + 1px));
  background: #d7d7d7;
}

.section-top-right-5 {
  top: 0;
  right: 0;
  transform: translateY(calc(200% + 2px));
  background: rgba(25, 164, 125, 0.6);
}

.section-bottom-left-1 {
  bottom: 0;
  left: 0;
  background: rgba(25, 164, 125, 0.6);
}

.section-bottom-left-2 {
  bottom: 0;
  left: 0;
  transform: translateX(calc(100% + 1px));
  background: rgba(25, 164, 125, 0.6);
}

.section-bottom-left-3 {
  bottom: 0;
  left: 0;
  transform: translateY(calc(-100% - 1px));
  background: #d7d7d7;
}

.section-bottom-left-4 {
  bottom: 0;
  left: 0;
  background: #d7d7d7;
}

.section-bottom-left-5 {
  bottom: 0;
  left: 0;
  transform: translateX(calc(100% + 1px)) translateY(calc(-100% - 1px));
  background: rgba(25, 164, 125, 0.6);
}

.section-bottom-left-6 {
  bottom: 0;
  left: 0;
  transform: translateY(calc(-200% - 2px));
  background: rgba(25, 164, 125, 0.6);
}

.vertical-workprocess .section-bottom-left-1 {
  background: #d7d7d7;
}

.vertical-workprocess .section-bottom-left-2 {
  background: #d7d7d7;
}

.vertical-workprocess .section-bottom-left-3 {
  background: rgba(25, 164, 125, 0.6);
}

.contact-page .section-bottom-left-6 {
  background: #d7d7d7;
}

.section-back-top-left-1 {
  top: 0;
  left: 0;
  transform: translateX(calc(100% + 1px)) translateY(calc(-100% - 1px));
  background: #d7d7d7;
}

.section-back-top-left-2 {
  top: 0;
  left: 0;
  transform: translateY(calc(-200% - 2px));
  background: rgba(25, 164, 125, 0.6);
}

.section-back-top-left-3 {
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 1px));
  background: #d7d7d7;
}

.section-back-top-left-4 {
  top: 0;
  left: 0;
  transform: translateX(calc(100% + 1px)) translateY(calc(-200% - 2px));
  background: rgba(25, 164, 125, 0.6);
}

.section-back-top-left-5 {
  top: 0;
  left: 0;
  transform: translateY(calc(-300% - 3px));
  background: #d7d7d7;
}

.section-back-bottom-right-1 {
  bottom: 0;
  right: 0;
  transform: translateX(calc(-100% - 1px)) translateY(calc(100% + 1px));
  background: #d7d7d7;
}

.section-back-bottom-right-2 {
  bottom: 0;
  right: 0;
  transform: translateY(calc(200% + 2px));
  background: rgba(25, 164, 125, 0.6);
}

.section-back-bottom-right-3 {
  bottom: 0;
  right: 0;
  transform: translateX(calc(-100% - 1px)) translateY(calc(300% + 3px));
  background: #d7d7d7;
}

.none-desktop {
  display: initial;
}

.banner-scroll-down {
  display: block;
  position: relative;
  padding-top: 22px;
  text-align: center;
  margin-top: 7px;
}

.arrow-down {
  display: block;
  margin: 0 auto;
  width: 10px;
}

.arrow-down:after {
  content: "";
  position: relative;
  display: block;
  margin: 2px 0 0 0;
  padding: 0;
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  /* behavior: url(-ms-transform.htc); */
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  z-index: 1;
}

.banner-scroll-down::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 50%;
  margin-left: -2px;
  width: 2px;
  height: 25px;
  background: #fff;
  -webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  -moz-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  -o-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
}

.black-friday-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}

.black-friday-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 30px);
  max-width: 900px;
  background: #fff;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  z-index: 10001;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  max-height: calc(100% - 30px);
  overflow-y: auto;
}

.black-friday-popup img {
  width: 100%;
}

.mobile-black-friday-img {
  display: none;
}

.black-friday-form {
  width: 100%;
  padding: 30px;
}

.black-friday-form-heading {
  display: none;
}

.black-friday-input-row:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 20px;
  width: calc(100% - 30px);
  height: calc(100% - 14px);
  border: 1px solid #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.black-friday-form-input {
  width: 100%;
  padding: 10px 15px 10px 15px;
  font-weight: 600;
  outline: 0;
  border: 1px solid #d7d7d7;
  color: #4b4b4b;
  margin-bottom: 14px;
  border-radius: 0;
}

.black-friday-form-input:focus {
  border: 1px solid #d967fd;
}

.black-friday-form-input::placeholder {
  color: #acabab;
}

.black-friday-form-textarea {
  width: 100%;
  padding: 10px 15px;
  font-weight: 600;
  outline: 0;
  border: 1px solid #d7d7d7;
  color: #4b4b4b;
  height: 125px;
  resize: none;
  border-radius: 0;
}

.black-friday-form-textarea::placeholder {
  color: #acabab;
}

.black-friday-form-submit {
  color: #fff;
  position: relative;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 10px 30px;
  z-index: 2;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
}

.black-friday-form-submit-area {
  display: inline-block;
  position: relative;
  margin-top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.black-friday-form-submit-area:before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #000;
  z-index: 2;
  transition: all 0.3s;
}

.black-friday-form-submit-area:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #d967fd;
  z-index: -1;
  transition: all 0.3s;
}

.black-friday-form-submit-area:hover:before {
  left: 0;
  top: 0;
  border: 2px solid #d967fd;
}

.black-friday-form-submit-area:hover:after {
  left: 0;
  top: 0;
}

.black-friday-top-p {
  font-style: italic;
  text-align: center;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.max-55-per {
  width: 100%;
  padding: 0 15px;
}

.black-friday-popup .max-55-per img {
  display: none;
}

.black-friday-popup .max-55-per img.mobile-black-friday-img {
  display: block;
}

.max-45-per {
  width: 100%;
  padding: 0 15px;
}

.close-popup,
.close-get-free-quote-popup {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #000;
  color: #fff;
  cursor: pointer;
}

.show-bg,
.show-popup {
  opacity: 1;
  visibility: visible;
}

.black-friday-term-privacy {
  display: none;
}

.pricing-sec .section-heading.pb-30 {
  padding-bottom: 20px;
}

#field-scroll {
  padding: 30px 0;
}

.section-heading.pb-30 {
  padding-bottom: 20px;
}

.hide-on-mob {
  display: none;
}

.width-100per {
  width: 100%;
}

label.width-100per {
  margin-bottom: 0;
}

.comparable-pricing-content {
  padding: 35px 15px;
}

.comparable-pricing-content .single-pricing {
  margin: 0 -15px;
  padding: 0 0 0 0;
}

.comparable-pricing-content .single-pricing .price-head {
  background: #e7f6f1;
  border: 1px solid #dadada;
  border-left: 0;
  padding: 22px 0;
}

.comparable-pricing-content .single-pricing:hover {
  top: 0;
  box-shadow: none;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .price-head {
  background: #d967fd;
  padding: 42px 10px;
  border-color: #d967fd;
}

.comparable-pricing-left-sec .price-head p,
.comparable-pricing-left-sec .price-head h4,
.comparable-pricing-left-sec .price-head h6 {
  color: #fff;
}

.comparable-pricing-content .single-pricing .price-head h6 sub {
  font-size: 25%;
  bottom: 0;
  color: #000;
  font-weight: 500;
}

.comparable-pricing-content .single-pricing.recommended-price:before {
  transform: rotate(90deg) translate(100%, 50%);
}

.comparable-pricing-content .single-pricing .price-head h4 {
  font-size: 18px;
}

.comparable-pricing-content .single-pricing .price-head p {
  font-size: 10px;
}

.comparable-pricing-content .single-pricing .price-head p span {
  font-size: 14px;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .price-head
  p {
  text-transform: initial;
  line-height: 1;
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
}

.comparable-pricing-content .price-head h6 {
  font-size: 40px;
}

.comparable-pricing-left-sec .price-head h5 {
  font-weight: 500;
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  margin-bottom: 0;
}

.comparable-pricing-content .single-pricing .pricing-detail {
  padding: 0;
}

.comparable-pricing-content .single-pricing .pricing-detail ul li {
  border: 1px solid #dadada;
  border-top: 0;
  border-left: 0;
  padding: 7px 10px;
  line-height: 2.99999999;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-detail
  ul
  li {
  text-align: left;
}

.comparable-pricing-content
  .single-pricing
  .pricing-detail
  ul
  li:nth-child(even) {
  background: #efefef;
}

.comparable-pricing-content .single-pricing .pricing-link {
  border: 1px solid #dadada;
  border-left: 0;
  border-top: 0;
  padding: 40px 0;
}

.comparable-pricing-content .single-pricing .pricing-link p {
  margin: 0;
  padding: 0 10px;
  font-weight: 600;
  text-align: left;
  line-height: 1.2;
  color: #000;
}

.comparable-pricing-content .single-pricing .pricing-link a {
  font-size: 14px;
  padding: 10px 15px;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-link {
  padding: 29px 0;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-link
  p
  a {
  padding: 5px 0 0 0;
  font-weight: 800;
  color: #000;
  font-size: 16px;
  transition: all 0.3s;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-link
  p
  a:hover,
.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-link
  p
  a
  i:before {
  color: #d967fd;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-link
  p
  a:before,
.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-link
  p
  a:after {
  display: none;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .price-head,
.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-detail
  ul
  li,
.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-link {
  border-left: 1px solid #dadada;
}

.comparable-pricing-content
  .single-pricing.comparable-pricing-left-sec
  .pricing-detail
  ul
  li {
  line-height: 1.5;
}

.section4 {
  padding: 80px 0 100px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #fff;
}

.section4::after {
  content: "MOBILE";
  font-size: 200px;
  font-weight: 900;
  color: #efefef;
  font-family: Oswald, sans-serif;
  position: absolute;
  top: 210px;
  letter-spacing: 10px;
  right: 10px;
  left: 150px;
  /* width: 100%; */
  z-index: -11;
}

.logo-section4::before,
.section5::after {
  color: rgb(255 255 255 / 5%);
  font-family: Oswald, sans-serif;
  letter-spacing: -20px;
}

.section4 .sec4_left .item h4 {
  font-size: 45px;
  font-weight: light;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section4 .sec4_left .item p span {
  margin-top: 2rem;
  display: block;
  font-weight: 400;
  font-size: 16px;
}

.section4 .sec4_right {
  position: relative;
}

.section4 .sec4_right::before {
  content: "";
  width: 176px;
  height: 383px;
  background: url("../src/assets/images/sec4_right_bg.webp") 0 0 / contain
    no-repeat;
  position: absolute;
  top: -15px;
  left: -18px;
  z-index: -1;
  display: none;
}
.mobile-img.absolute {
  height: 430px;
  top: -15px;
  width: 210px;
  left: -24px;
}
.mobile-slider-img {
  width: 176px;
  height: 383px;
}
.section4 .sec4_right .item.slick-slide {
  /* margin-right: 20px;
  margin-left: 20px; */
}

.slick-initialized .slick-slide,
.slick-slide img {
  display: block;
  max-width: 95%;
}

.item {
  width: 100%;
  /* opacity: 0; */
  transition: opacity 1s ease-in-out;
}

.crause {
  display: flex;
  animation: carouselAnimation 9s infinite linear;
  /* Adjust the animation duration as needed */
}
.light-purple-bg{
  background-color: #e6dff0;
}
@keyframes carouselAnimation {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }

  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-height: 700px) {
  .absolute-bottom-link {
    bottom: 80px;
  }
}

@media only screen and (max-height: 600px) {
  .absolute-bottom-link {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .home-banner .absolute-center {
    max-width: 90%;
    width: 100%;
    top: 50%;
  }
  .port-nav-tb li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .port-nav-tb ul li {
    /* max-width: 90%;
    width: 100%; */
  }
  .price-nav-tb ul li {
    border: transparent;
  }
  .white-sec {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f3f3f3;
  }
  /* 
  .section4 .sec4_right::before {
    width: 180px;
    height: 322px;
    left: 0;
    top: -11px;
  } */

  .onload-animate,
  .animated {
    display: none;
  }

  .cmb-list-st {
    flex-direction: column;
  }

  .cmb-txt-hd:before {
    width: auto;
  }

  .cmb-list-st ul {
    width: 100%;
  }

  ul.cmb-ul-st-3 img {
    top: 0;
  }

  .cmb-txt-hd h2 {
    font-size: 22px;

    padding-bottom: 8px;
  }

  .link-area a.hover-effect {
    bottom: 10px;
  }

  .section4::after {
    font-size: 50px;
    top: 0;
    left: 3rem;
    letter-spacing: normal;
  }
}

.top-mob {
  position: relative;
  top: auto;
  transform: none;
  padding-top: 55px;
}

.step-center.top-48-desktop.top-mob {
  position: relative;
  top: 48%;
  transform: translateY(-50%);
  padding-top: 55px;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-25 {
  padding-bottom: 25px;
}

.plr-30 {
  padding: 0 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mlr--7 {
  margin-left: -7px;
  margin-right: -7px;
}

.plr-7 {
  padding-right: 7px;
  padding-left: 7px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.p-mob-0 {
  padding: 0;
}

.mlr-7-mob {
  margin-left: -7px;
  margin-right: -7px;
}

.mt-5-mob {
  margin-top: 5px;
}

.mt-0-mob {
  margin-top: 0;
}

.animation-delay-5ms {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animation-delay-7ms {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.animation-delay-9ms {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.animation-delay-10ms {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animation-delay-11ms {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.animation-delay-13ms {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.animation-delay-15ms {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.animation-delay-17ms {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.animation-delay-19ms {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

.animation-delay-20ms {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animation-delay-25ms {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.fa-check-circle:before {
  content: "\f058";
}

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-play:before {
  content: url("../src/assets/images/video-play-btn-black.png");
}

.fa-play-circle:before {
  content: url("../src/assets/images/video-play-btn-black.png");
}

.fa-playstation:before {
  content: url("../src/assets/images/video-play-btn-black.png");
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }

  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

@-moz-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }

  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

@-o-keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }

  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

@keyframes elasticus {
  0% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }

  50% {
    -webkit-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  50.1% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    -webkit-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    -moz-transform: scale(1, 0);
    -o-transform: scale(1, 0);
    transform: scale(1, 0);
  }
}

/*------------------ For Blog mobile View --------------------------*/

.blog-detail-page .section-heading h2,
.blog-detail-page .section-heading h6,
.blog-top-tags-ul,
.blog-page-user-detail > h6 {
  font-family: "Georgia", serif;
}

.moreblogsdiv {
  padding: 0px 15px;
  margin-bottom: 20px;
}

.moreblogsdiv > div:nth-child(1) {
  width: 75%;
}

.moreblogsdiv > div:nth-child(2) {
  width: 25%;
}

.moreblogsdiv > div:nth-child(1) p {
  font-weight: 800;
  margin-bottom: 8px;
}

.moreblogsdiv > div:nth-child(1) small {
  font-size: 15px;
}

.moreblogsdiv p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 1.4;
  color: #292929;
  font-family: "Helvetica Neue", sans-serif;
}

.blogstopulforotherscreen {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0 0 7px;
  color: #d967fd;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
}

.blogstopulforotherscreen > li:nth-child(1) {
  padding: 0 7px 0 0;
  border-right: 2px solid #d967fd;
}

.blogstopulforotherscreen > li:nth-child(2) {
  padding: 0 7px;
  border-right: 2px solid #d967fd;
}

.blogstopulforotherscreen > li:nth-child(3) {
  padding: 0 7px;
}

.blogleftshareotherscreen {
  padding: 80px 15px 0px;
  display: none;
}

.blogleftshareotherscreen > div h6 {
  font-size: 14px;
}

.blogleftshareotherscreen > div h5 {
  font-size: 19px;
  font-weight: 700;
}

.blogleftshareotherscreen > ul.blogsideshareicon {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.blogleftshareotherscreen > ul.blogsideshareicon li {
  padding: 10px 10px;
}

.blogleftshareotherscreen > ul.blogsideshareicon > li a {
  text-decoration: none;
  color: #4b4b4b;
}

.padding-0mobile {
  padding: 0px;
}

.blogspageuserimg > img {
  width: auto;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

/*.socialiconsrowmobile>div.col-md-6{
      width: 50%;
  }*/
.blogspagemobile {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.blogspagemobile > li {
  padding: 0px 3px;
}

.blogspagemobile > li > a > i {
  font-size: 25px;
}

.blogspagemobile > li:nth-child(1) > a > i {
  color: #3b5a9a;
}

.blogspagemobile > li:nth-child(2) > a > i {
  color: #d54a35;
}

.blogspagemobile > li:nth-child(3) > a > i {
  color: #c70712;
}

.blogspagemobile > li:nth-child(4) > a > i {
  color: #23a8e1;
}

.blogspagemobile > li:nth-child(5) > a > i {
  color: #117bb5;
}

.replycoldiv,
.likereplyicon {
  display: flex;
}

.replycoldiv > div.replyimgdiv {
  width: 25%;
  padding-right: 10px;
}

.replycoldiv > div.replycontentdiv {
  width: 75%;
}

.replycoldiv > div.replycontentdiv {
  width: 75%;
}

.replycoldiv > div.replycontentdiv h6 {
  font-size: 13px;
}

.replycoldiv > div.replycontentdiv h5 {
  font-weight: 700;
}

.replycoldiv > div.replycontentdiv p {
  line-height: 1.4;
  font-size: 14px;
}

.likereplyicon p {
  font-size: 12px;
}

.likereplyicon > p > a {
  text-decoration: none;
  color: #4b4b4b;
}

.likereplyicon > p > a > i {
  color: #d967fd;
}

.replyform {
  margin-bottom: 20px;
}

.replyform input {
  padding: 10px 15px 10px 10px;
  height: 44px;
  border-radius: 0px;
  font-weight: 600;
  border-right: 0px;
  font-size: 16px;
}

.replyform input:focus {
  box-shadow: none;
  border-color: #d967fd;
}

.replyform div.input-group-text {
  background: transparent;
  border-left: 0px;
}

.replyform div.input-group-text i {
  color: #d967fd;
}

.morefromcaterheaddiv > h2 {
  color: #080808;
  font-weight: 500;
  font-size: 28px;
  font-family: "Georgia", serif;
}

.blog-detail-page .section-heading {
  text-align: left;
}

.blog-detail-page .section-heading h2 {
  margin-bottom: 0.5rem;
}

.blog-page-user-detail-area {
  display: flex;
  align-items: center;
}

.blog-page-user-detail {
  padding-left: 25px;
}

.blog-page-user-detail > p {
  font-size: 12px;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}

.blog-page-user-detail > h6 {
  margin: 0;
  font-size: 19px;
  font-weight: 600;
  color: #292929;
}

.blog-detail-page .blog-detail-area {
  padding: 30px 0 0;
  box-shadow: none;
}

.blog-detail-page .single-blog,
.blog-detail-page .blog-detail-area h3 {
  font-family: "Charter", serif;
}

.blog-detail-page .blog-detail-area {
  font-size: 17px;
  color: #292929;
}

.blog-detail-page .blog-detail-area h3,
.blog-detail-page .blog-detail-area h3:hover {
  font-size: 23px;
}

.blog-detail-page .blog-detail-area b {
  font-size: 21px;
  /*font-family: "Helvetica Neue", sans-serif;*/
}

.blog-detail-page .blog-comment-sec {
  padding: 0 8px;
  margin: 0;
}

.blog-detail-page .blog-comment-sec + .blog-comment-sec {
  padding-top: 35px;
}

.blog-detail-page .blog-comment-sec .section-heading h2 {
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 10px;
  font-family: "Charter", serif;
}

.blog-detail-page .blog-comment-sec .section-heading h2 sub {
  float: right;
  padding-top: 15px;
  bottom: 0;
  font-size: 45%;
  color: #969696;
  padding-left: 10px;
}

.fetch-pricing-section-1 .link-area a i:before {
  color: #fff;
  transition: all 0.3s;
}

.fetch-pricing-section-1 .link-area a.simple-a:hover {
  padding-right: 45px;
}

.fetch-pricing-section-1 .link-area a i {
  font-size: 100%;
  margin-right: 0px;
  bottom: auto;
  margin-left: 0;
  position: absolute;
  top: 49%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.fetch-pricing-section-1 .link-area a:hover i {
  margin-left: 5px;
  opacity: 1;
  visibility: visible;
}

.fetch-pricing-section-1 .link-area a:hover i:before {
  color: #d967fd;
}

/*------------------ For Galaxy Fold --------------------------*/

@media only screen and (max-width: 280px) {
  .banner-heading h1 {
    font-size: 26px !important;
  }
}
@media only screen and (max-width: 385px) {
  .mobile-slider-img {
    width: 150px;
    height: 300px;
  }
  .mobile-img.absolute {
    height: 338px;
    top: -10px;
    width: 184px;
    left: -22px;
  }
}
@media only screen and (max-width: 320px) {
  .mobile-slider-img {
    width: 100px;
    height: 200px;
  }
  .mobile-img.absolute {
    height: 228px;
    top: -10px;
    width: 130px;
    left: -20px;
  }
}
